<template>
    <div class="login_container fade">
        <v-container>

            <v-img contain class="my-2" height="150" src="@/assets/logo-white.png"></v-img>

            <v-card class="overflow-hidden mx-auto mt-8 mb-12" width="500" color="darkbackground">
                <v-card-text>
                    <v-slide-x-reverse-transition leave-absolute>
                        <v-form v-if="mode === 'forgot_password'" class="w-full" @submit.prevent="resetPassword">
                            <v-card tile flat color="darkbackground">
                                <v-card-title class="primary--text font-weight-bold ">
                                    Esqueceu sua senha?
                                </v-card-title>

                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-alert color="#E0A585" text class=" text-center mb-0 text-body-2">
                                                Instruções para redefinir sua senha serão enviadas para o seu email.
                                            </v-alert>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-text-field v-model="email" label="Email" outlined hide-details="auto" autocomplete="email" />
                                        </v-col>

                                        <v-col cols="12">
                                            <v-btn depressed large color="primary" width="100%" type="submit">
                                                Enviar
                                            </v-btn>
                                        </v-col>

                                        <v-col cols="12" class="d-flex justify-center">
                                            <div>
                                                Espere, eu lembro minha senha! <a class=" text-none" @click="mode = 'login'">Voltar</a>
                                            </div>
                                        </v-col>

                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-form>

                    </v-slide-x-reverse-transition>

                    <v-slide-x-transition leave-absolute>

                        <v-form v-if="mode !== 'forgot_password'" @submit.prevent="login">
                            <v-card tile flat color="darkbackground">
                                <v-card-title class="primary--text font-weight-bold">
                                    Bem-vindo de volta!
                                </v-card-title>

                                <v-card-subtitle>
                                    Entre ou cadastre-se já.
                                </v-card-subtitle>

                                <v-card-text>
                                    <v-row dense>
                                        <v-col cols="12">
                                            <v-text-field v-model="email" label="Email" outlined hide-details="auto" />
                                        </v-col>

                                        <v-col cols="12" class="mb-2">
                                            <v-text-field v-model="password" :type="showPassword ? 'text' : 'password'" label="Senha" outlined hide-details="auto" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" />
                                        </v-col>

                                        <v-col cols="12" class="d-flex align-center justify-sm-end">
                                            <div small text class=" text-none text-caption" @click="mode = 'forgot_password'">
                                                Esqueceu sua senha?
                                            </div>
                                        </v-col>

                                        <v-col cols="12" class="mt-2">
                                            <v-btn large color="primary darken" width="100%" type="submit">
                                                Entrar
                                            </v-btn>
                                        </v-col>

                                        <!--
                                        <v-col cols="12" class="mb-6 mt-6">
                                            <v-divider color="gray"></v-divider>
                                        </v-col>

                                        

                                        <v-col cols="12">
                                            Ainda não tem uma conta? Cadastre-se, é grátis!
                                        </v-col>

                                        <v-col cols="12">
                                            <v-btn large color="primary" width="100%" @click.native="$router.push('/cadastro')">
                                                Criar uma conta
                                            </v-btn>
                                        </v-col>
                                        
                                        -->

                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-form>
                    </v-slide-x-transition>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "LoginView",
    data() {
        return {
            // mode
            mode: "login",

            // Form
            email: "",
            password: "",
            remember: true,
            showPassword: false,
        };
    },
    methods: {
        async login() {
            const response = await Api.login(this.email, this.password, this.remember);

            if (response.error) {
                this.$toast('error', response.message);
                return
            }

            this.$router.push("/");
        },
        async resetPassword() {
            const resp = await Api.recoveryPassword(this.email);

            if (resp && !resp.error) {
                emitToastr('success', 'Email enviado com sucesso! Verifique sua caixa de entrada, incluindo a pasta de Spam.');
                this.mode = 'login';
                return;
            }

            if (resp && resp.error && resp.error.message) {
                emitToastr('error', resp.error.message)
                return;
            }
        }
    },
};
</script>

<style scoped>
.login_container {
    display: flex;
    align-items: start;
    justify-content: center;
    height: 100%;
}

.login_background_pattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.login_container {
    background: linear-gradient(-45deg, #00630d, #00630d, #00fc22, #262626);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
</style>