<template>
  <v-app>
    <app-confirm-dialog />

    <v-main v-if="$route.meta.layout === 'fullscreen'">
      <router-view></router-view>
    </v-main>

    <div v-if="$route.meta.layout === 'menu'">

      <app-drawer v-model="drawer" />

      <v-main>
        <v-app-bar color="darkbackground" :clipped-left="$vuetify.breakpoint.lgAndUp" elevation="0" class="app_bar grey--text">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="grey" />

          <v-spacer></v-spacer>

          <div class="d-flex align-center noselect">
            <v-img class="header-img" src="@/assets/logo.png" contain />
          </div>

          <v-spacer></v-spacer>

          <v-btn small icon color="grey" @click="$router.push({ name: 'Ajuda' }).catch(() => { })">
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>


          <v-menu offset-y>
            <template #activator="{ on }">
              <v-badge overlaps color="warning" :value="openConversations" :content="openConversations" offset-x="12" offset-y="22" class="cursor-pointer mr-2" style="pointer-events: inherit;">
                <v-btn icon v-on="on" color="grey">
                  <v-icon>mdi-bell</v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-list dense>
              <v-list-item @click="$router.push({ name: 'Atendimento' }).catch(() => { })">
                <v-list-item-title>
                  {{ openConversations === 0 ? 'Sem atendimento humano aberto' : openConversations > 1 ?
                    `${openConversations}
                  atendimentos humanos em andamento` : `${openConversations} atendimento humano aberto` }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu offset-y>

            <template #activator="{ on }">
              <v-btn text v-on="on" height="48">
                <div class="d-flex align-center">
                  <v-avatar size="32" class="mr-md-4">
                    <v-img v-if="currentUser?.profile_picture" :src="currentUser?.profile_picture" />
                    <div v-else class="w-full h-full primary d-flex justify-center">
                      <v-icon color="white">mdi-account</v-icon>
                    </div>
                  </v-avatar>

                  <div class="grey--text text-left text-none d-none d-sm-block">
                    <div class="font-weight-bold text-subtitle-2">{{ currentUser?.name }}</div>
                  </div>
                </div>
              </v-btn>
            </template>

            <v-list>
              <v-list-item to="/meu-perfil">
                <v-list-item-title>
                  <v-icon size="22" class="mr-2">mdi-account</v-icon>
                  Meu cadastro
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="currentUser?.system_user_roles?.includes('customer_admin')" to="/admin-clientes">
                <v-list-item-title>
                  <v-icon size="22" class="mr-2">mdi-account-multiple-outline</v-icon>
                  Administração de clientes
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="currentUser?.system_user_roles?.includes('tech_dashboard')" to="/dash-tecnico">
                <v-list-item-title>
                  <v-icon size="22" class="mr-2">mdi-monitor-dashboard</v-icon>
                  Dashboard técnico
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="currentUser?.system_user_roles?.includes('financial_dashboard')" to="/dash-financeiro">
                <v-list-item-title>
                  <v-icon size="22" class="mr-2">mdi-finance</v-icon>
                  Dashboard financeiro
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="['owner'].includes(currentUser?.user_role)" to="/minha-conta">
                <v-list-item-title>
                  <v-icon size="22" class="mr-2">mdi-chart-box-outline</v-icon>
                  Minha assinatura
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="logout">
                <v-list-item-title>
                  <v-icon size="22" class="mr-2">mdi-exit-run</v-icon>
                  Sair
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>

        <v-divider />

        <template v-if="pageLoading">
          <v-progress-linear indeterminate color="primary" class="loading-bar" />
        </template>

        <!--<app-subheader v-show="!pageLoading" />-->

        <v-divider v-show="!pageLoading" />

        <v-container fluid v-show="!pageLoading" class="pa-0">
          <div>
            <router-view v-if="currentUser" />
          </div>
        </v-container>
      </v-main>
    </div>

    <pastdue-payment-dialog v-if="currentUser?._id"></pastdue-payment-dialog>
  </v-app>
</template>

<script>
import Api from "@/lib/Api";
let socket;
let socket_connection_count = 0;
import { emitToastr } from "./lib/Utils";

export default {
  name: "App",
  components: {
    //AppSubheader: () => import("@/components/AppSubheader.vue"),
    AppDrawer: () => import("@/components/AppDrawer.vue"),
    AppConfirmDialog: () => import("@/components/AppConfirmDialog.vue"),
    PastduePaymentDialog: () => import("@/components/PastduePaymentDialog.vue"),
  },

  data: () => ({
    drawer: false,
    projectName: "InteligênciaBot",
    myCallbacks: {
      onPreviousStep: () => { },
      onNextStep: () => { },
    },
    tourOptions: {
      useKeyboardNavigation: false,
      labels: {
        buttonSkip: 'Pular onboarding',
        buttonPrevious: 'Voltar',
        buttonNext: 'Próximo',
        buttonStop: 'Concluir'
      }
    },
    steps: [
      {
        target: '#v-step-0',
        content: `Bem-vindo ao InteligênciaBot. Vamos fazer um rápido tour pela plataforma. Clicando em seu nome ou foto, você poderá visualizar e editar seus dados pessoais, assim como redefinir sua senha.`,
        params: {
          placement: 'bottom',
          highlight: true
        }
      },
      {
        target: '#v-step-1',
        content: 'No dashboard você poderá ver seu relatório de envio de mensagens e consumo de créditos.',
        params: {
          placement: 'bottom',
          highlight: true
        }
      },
      {
        target: '#v-step-2',
        content: 'Na seção Plano e créditos você poderá comprar créditos e assinar o plano premium, além de conferir seu histórico de compras pontuais e recorrentes.',
        params: {
          placement: 'bottom',
          highlight: true
        }
      },
      {
        target: '#v-step-3',
        content: 'Essa seção tem grande importância. Todo envio de mensagem parte de um número/chip conectado, então é crucial conectar uma conta do Whatsapp aqui.',
        params: {
          placement: 'bottom',
          highlight: true
        }
      },
      {
        target: '#v-step-4',
        content: 'Os templates são utilizados em integrações, para enviar mensagens dinâmicas e variadas, por exemplo: chamando o cliente pelo nome {name} e mostrando o status de uma compra {status}.',
        params: {
          placement: 'bottom',
          highlight: true
        }
      },
      {
        target: '#v-step-5',
        content: 'Essa área é a última etapa do processo de configuração, exibindo a chave de api e algumas dicas.',
        params: {
          placement: 'bottom',
          highlight: true
        }
      }
    ]
  }),

  computed: {
    pageLoading() {
      return this.$store.state.pageLoading
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    openConversations() {
      return this.$store.state.conversations.open_manual_count;
    }
  },

  created() {
    this.$root.$refs.global = this;
  },

  async mounted() {
    this.$vuetify.breakpoint.lgAndUp ? (this.drawer = true) : (this.drawer = false);
    this.$root.$refs.showTips = this.showTips;

    const storage_user = await Api.getUser();

    if (storage_user) {
      await this.$store.dispatch('loadCurrentUser');
      if (this.currentUser?._id) {
        this.$store.dispatch('conversations/load_open_manual_count');


        // INÍCIO DO FEEDELIZE
        let user_role = 'Atendente';
        if (this.currentUser.user_role === 'owner') user_role = 'Dono';
        if (this.currentUser.user_role === 'manager') user_role = 'Gerente';

        let tags = [user_role];

        if (this.currentUser.account?.max_channels) tags.push(`${this.currentUser.account?.max_channels} chips`);
        if (this.currentUser.account?.max_users) tags.push(`${this.currentUser.account?.max_users} atendentes`);

        // eslint-disable-next-line
        feedelize = {
          project_id: 'ib',
          user_id: this.currentUser._id,
          user_name: this.currentUser.name,
          user_email: this.currentUser.email,
          tags: tags
        };

        setTimeout(() => {
          const script = document.createElement('script');
          script.src = "https://app.feedelize.com.br/widget.js";
          script.async = true;
          document.body.appendChild(script);
        }, 1500);

        // FIM DO FEEDELIZE
      }


      this.startSocket();
    }
  },

  methods: {
    logout() {
      Api.logout();
    },

    startSocket() {
      if (socket_connection_count >= 3) {
        emitToastr("error", "Erro na conexão com o socket. Atualize a página para tentar novamente.");
        return;
      }

      if (!this.$store.state.SOCKET_IO_URL) {
        socket_connection_count++
        setTimeout(() => { this.startSocket(); }, 500 + socket_connection_count * 2000);
        emitToastr("info", "Aguardando endereço do socket...");
        return;
      }

      socket_connection_count = 0;
      socket = this.$io(this.$store.state.SOCKET_IO_URL, { path: '/sock/' });

      socket.on("connect", () => {
        socket.emit("join-room", `notifications_${this.currentUser?.account?._id}`);
      });

      socket.on("receive-message", data => {
        if (data.event === "load_open_conversations") {
          //this.$store.dispatch('conversations/load_open_manual_count');
          if (this.$route.name == 'Dashboard') this.$store.dispatch('realtime/load');
        }

        if (data.event === "load_realtime") {
          if (this.$route.name == 'Dashboard') this.$store.dispatch('realtime/load');
        }

      });
    },

    showTips() {
      return;
      // eslint-disable-next-line
      this.myCallbacks = {
        onPreviousStep: this.myCustomCallback,
        onNextStep: this.myCustomCallback
      };
      if (!this.drawer) this.drawer = true;
      this.$tours['myTour'].start();
      window.localStorage.setItem("tips1", "ok");
    },

    myCustomCallback() {
      setTimeout(() => {
        this.drawer = true;
      }, 200);
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler: async function () {
        this.$store.commit('setBreadcrumbs', [])
        this.$store.commit('setPageTitle', null)
        this.$store.commit('setPageSubtitle', null)

        if (this.currentUser) return

        if (this.$route.meta.layout === 'fullscreen') return
        if (this.$route.path === '/login' || this.$route.path === '/cadastro') return

        await this.$store.dispatch('loadCurrentUser');

      },
    }
  },


};
</script>

<style scoped>
.loading-bar {
  position: absolute;
  z-index: 99999 !important;
}

.header-title {
  font-size: 16px;
  font-weight: 600;
  color: #2eb339;
}

.header-img {
  height: 50px;
  width: 220px;
}

@media only screen and (max-width: 600px) {
  .header-img {
    height: 35px !important;
    width: 180px !important;
  }
}
</style>
