<template>
    <div class="menu-page">
        <v-alert outlined type="warning" icon="mdi-crown" prominent>
            <div>Atualize sua assinatura, método de pagamento ou cancelamento aqui.</div>
            <div class="mt-2">
                <v-btn outlined color="warning" class="mb-1" @click="openLink('https://digitalmanager.guru/myorders')">Gestão do plano</v-btn>
            </div>
        </v-alert>
    </div>
</template>

<script>
export default {
    name: "ContaView",

    data() {
        return {
            loading: true,
            subscription: false,
            is_active: false,
            payments: [],
            deleteDialog: false,
            status: "none",
            statuses: {
                "none": "Sem assinatura",
                "active": "Assinatura ativa",
                "canceled": "Assinatura cancelada",
                "expired": "Assinatura expirada",
                "inactive": "Assinatura inativa",
                "pastdue": "Assinatura em atraso",
                "started": "Assinatura iniciada",
                "trial": "Assinatura em período de testes",
                "trial canceled": "Assinatura cancelada"
            },
            headers_subscriptions: [
                { text: 'Data', value: 'created' },
                { text: 'Valor', value: 'amount' },
                { text: "Renovação?", value: "is_cycle" }
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        },
        subscriptions() {
            return []
        },
    },

    async mounted() {

    },

    methods: {
        async init() {

        },

        openLink(link) {
            window.open(link, "_blank");

            document.addEventListener("visibilitychange", async () => {
                
            });
        }
    },
};
</script>

<style scoped>
.small-text {
    font-size: 12px;
}
</style>