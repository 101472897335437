<template>
    <div class="menu-page">
        <v-card>
            <v-card-title>
                Mensagens rápidas
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-btn color="primary" @click.native="openNew">Nova mensagem rápida</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="objects" :loading="loading">

                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon medium class="mr-2" color="error lighten-1" @click="confirmDeleteQuickMessage(item)" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>Apagar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon medium color="primary" @click="openQuickMessage(item)" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        
        <v-dialog v-model="dialog" scrollable max-width="600" persistent>
            <v-card>
                <v-card-title>
                    Mensagem Rápida
                </v-card-title>
                <v-card-subtitle>
                    Crie ou modifique suas mensagens rápidas para responder com mais agilidade nos atendimentos humanos
                </v-card-subtitle>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field class="pt-1" v-model="object.shortcut" label="Atalho" outlined :messages="`O atalho para a mensagem abaixo será /${object.shortcut || ''}`" prefix="/" />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea class="pt-1" v-model="object.message" label="Mensagem" placeholder="Escreva a mensagem completa aqui" outlined rows="6" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click.native="dialog = false">Cancelar</v-btn>
                    <v-btn color="primary" @click.native="save" :loading="loading">Salvar</v-btn>
                </v-card-actions>

            </v-card>

        </v-dialog>


        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="deleteDialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar mensagem rápida
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar a mensagem rápida <b>/{{ object.shortcut }}</b>?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="deleteDialog = false; deleteQuickMessage()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
//import { emitToastr } from "@/lib/Utils";


export default {
    name: "MensagensRapidasView",

    data() {
        return {
            objects: [],
            object: {},
            loading: true,
            dialog: false,
            deleteDialog: false,
            headers: [
                { text: 'Atalho', value: 'shortcut', sortable: false },
                { text: 'Mensagem', value: 'message', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },


    async mounted() {
        await this.getQuickMessages();
    },

    methods: {
        async getQuickMessages() {
            this.loading = true;
            const resp = await Api.getQuickMessagesByUser();
            this.loading = false;

            if (!resp.error && resp.message) {
                this.objects = resp.message;
            }
        },

        openNew() {
            this.object = {};
            this.dialog = true;
        },

        confirmDeleteQuickMessage(item) {
            this.object = item;
            this.deleteDialog = true;
        },

        async deleteQuickMessage() {
            this.object.filed = true;
            const resp = await Api.createOrUpdateQuickMessage(this.object);
            if (!resp.error && resp.message) {
                emitToastr("success", "Mensagem rápida apagada com sucesso");
                this.object = {};
                this.getQuickMessages();
            }
        },

        openQuickMessage(item) {
            this.object = item;
            this.dialog = true;
        },

        async save() {
            this.loading = true;
            if (!this.object.shortcut) return emitToastr("error", "O atalho é um campo necessário");
            if (!this.object.message) return emitToastr("error", "A mensagem é um campo necessário");

            this.object.message.split('/').join('');

            const resp = await Api.createOrUpdateQuickMessage(this.object);
            this.loading = false;

            if (!resp.error) {
                emitToastr("success", "Mensagem rápida salva com sucesso");
                this.getQuickMessages();
                this.dialog = false;
            }

        }
    },
};
</script>

<style scoped></style>