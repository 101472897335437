<template>
    <div class="menu-page">
        <v-alert prominent class="success lighten-1" dark icon="mdi-school-outline">
            <v-row align="center">
                <v-col class="grow">
                    Quer aprender a criar fluxos incríveis? Entenda o funcionamento de cada componente assistindo nossos vídeos e tire proveito do que há de melhor na plataforma.
                </v-col>
                <v-col class="shrink" @click="$router.push('/ajuda')">
                    <v-btn>Ver vídeos</v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <v-card>
            <v-card-title>
                Chatbots de fluxo
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-btn color="primary" @click.native="dialog = true">Novo fluxo</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="items" :loading="loading">

                            <template v-slot:[`item.keywords`]="{ item }">
                                <span v-if="!item.trigger">Não</span>
                                <div v-if="item.trigger && item.keywords">
                                    <v-chip small class="mr-1 my-1" v-for="(keyword, index) in item.keywords" color="warning" :key="`keyword_${index}`" label>{{ keyword }}</v-chip>
                                </div>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex">
                                    
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="openOptionsDialog(item)">
                                                <v-icon medium color="primary" v-bind="attrs" v-on="on">mdi-flash-auto</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ativar por gatilho</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon :to="`/flows/${item._id}`">
                                                <v-icon medium color="primary" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="selectedFlow = item; dialog2 = true">
                                                <v-icon medium color="primary" v-bind="attrs" v-on="on">mdi-content-duplicate</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Duplicar fluxo</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="deleteItem(item)">
                                                <v-icon medium class="mr-2" color="error lighten-1" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Apagar</span>
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" scrollable max-width="600" persistent>
            <v-card>
                <v-card-title>
                    Nome do fluxo
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="payload.name" label="Nome" outlined hide-details="auto" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click="dialog = false">Cancelar</v-btn>
                    <v-btn color="primary" @click="save" :loading="saving">Salvar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog2" scrollable max-width="600" persistent>
            <v-card>
                <v-card-title>
                    Duplicar fluxo {{ selectedFlow.name }}
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="payload.name" label="Nome do novo fluxo" outlined hide-details="auto" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click="dialog2 = false">Cancelar</v-btn>
                    <v-btn color="primary" @click="duplicateFlow" :loading="saving">Duplicar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog3" scrollable max-width="600" persistent>
            <v-card>
                <v-card-title>
                    Gatilhos por palavra-chave
                </v-card-title>
                <v-card-subtitle>
                    Cadastre palavras ou frases enviadas por seus contatos que ativarão esse fluxo automaticamente
                </v-card-subtitle>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-switch dense inset class="my-1" v-model="payload.trigger" label="Ativar com palavras-chave" hide-details="auto"></v-switch>
                        </v-col>

                        <v-col cols="12" v-if="payload.trigger">
                            <v-text-field class="pt-1" v-model="new_keyword" label="Nova palavra-chave" placeholder="Digite a aperte ENTER" outlined hide-details="auto" @keyup.enter="addItem(new_keyword)" append-outer-icon="mdi-plus" @click:append-outer="addItem(new_keyword)" />
                        </v-col>

                        <v-col cols="12" v-if="payload.trigger && payload.keywords">
                            <label>Palavras-chave enviadas pelo contato que irão ativar esse fluxo</label>
                            <div>
                                <v-chip v-for="(item, index) in payload.keywords" dark close @click:close="payload.keywords.splice(index, 1)" class="primary mr-2 my-2" :key="index">{{ item }}</v-chip>
                                <v-chip small class="warning" v-if="!payload.keywords || payload.keywords.length == 0"> Crie pelo menos uma palavra-chave </v-chip>
                            </div>
                        </v-col>

                        <v-col cols="12" v-if="payload.trigger">
                            <v-switch dense inset class="my-1" v-model="payload.trigger_human" label="Ativo em atendimento humano" hide-details="auto"></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click.native="dialog3 = false">Cancelar</v-btn>
                    <v-btn color="primary" @click.native="updateOptions" :loading="loading">Salvar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import StartNode from '@/flow-editor/nodes/StartNode';

export default {
    name: "FlowList",
    data() {
        return {
            items: [],
            payload: {},
            dialog: false,
            dialog2: false,
            dialog3: false,
            loading: false,
            saving: false,
            selectedFlow: {},
            new_keyword: '',
            headers: [
                { text: 'Nome', value: 'name', sortable: false },
                { text: 'Gatilhos', value: 'keywords', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false, width: 100 },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },
    async mounted() {
        await this.load();
    },
    methods: {
        openOptionsDialog(item) {
            this.payload = JSON.parse(JSON.stringify(item));
            this.dialog3 = true;
        },

        addItem(item) {
            if (!item || item == '') return this.$api.emitToastr("error", "Palavra-chave necessária");
            if (this.payload.keywords.find(k => k.toLowerCase() == item.toLowerCase())) return this.$api.emitToastr("error", "Palavra-chave já cadastrada");
            this.payload.keywords.push(item);
            this.new_keyword = "";
        },

        async load() {
            this.loading = true;

            const response = await this.$api.getFlowsByUser();

            if (response.error) return

            this.items = response.message;

            setTimeout(() => {
                this.loading = false;
            }, 800);
        },

        async deleteItem(item) {
            const confirmDelete = await this.$store.dispatch('confirmDialog', {
                title: "Apagar fluxo",
                message: `Deseja realmente apagar o fluxo ${item.name}?`
            });

            if (!confirmDelete) return

            const response = await this.$api.createOrUpdateFlow({
                ...item,
                filed: true
            });

            if (response.error) return

            this.$toast("success", "Fluxo apagado com sucesso");

            await this.load();
        },

        async duplicateFlow() {
            if (!this.payload.name) {
                return this.$toast("error", "O nome é um campo necessário");
            }

            this.saving = true;
            const response = await this.$api.duplicateFlow(this.selectedFlow._id, this.payload.name);

            if (response.error) {
                this.saving = false;
                return
            }

            this.$toast("success", "Fluxo criado com sucesso");

            await this.load();

            setTimeout(() => {
                this.saving = false;
                this.dialog2 = false;
            }, 800);
        },

        async updateOptions() {
            this.saving = true;

            if (this.payload.trigger && !this.payload.keywords.length) return this.$toast("error", "É necessário adicionar pelo menos uma palavra-chave");

            const response = await this.$api.createOrUpdateFlow(this.payload);

            if (response.error) {
                this.saving = false;
                return
            }

            this.$toast("success", "Fluxo salvo com sucesso");

            await this.load();

            setTimeout(() => {
                this.saving = false;
                this.dialog3 = false;
            }, 400);
        },

        async save() {
            if (!this.payload.name) {
                return this.$toast("error", "O nome é um campo necessário");
            }

            this.saving = true;

            const response = await this.$api.createOrUpdateFlow({
                name: this.payload.name,
                nodes: [new StartNode()],
            });

            if (response.error) {
                this.saving = false;
                return
            }

            this.$toast("success", "Fluxo salvo com sucesso");

            await this.load();

            setTimeout(() => {
                this.saving = false;
                this.dialog = false;
            }, 400);
        }
    },
};
</script>