<template>
    <div class="login_container fade">
        <v-container>

            <v-img contain class="my-2" height="150" src="@/assets/logo-white.png"></v-img>

            <v-card class="overflow-hidden mx-auto mt-8 mb-12" width="500" color="darkbackground">
                <v-card-text v-if="logged">
                    Você está logado. Entre com navegação anônima ou deslogue o acesso atual.
                </v-card-text>

                <v-card-text v-if="!logged">
                    <v-form @submit.prevent="login">
                        <v-card tile flat color="darkbackground">
                            <v-card-title class="primary--text font-weight-bold">
                                Login de administrador
                            </v-card-title>

                            <v-card-text>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-text-field v-model="customer_email" label="Email do usuário" outlined hide-details="auto" />
                                    </v-col>

                                    <v-col cols="12">
                                        <v-text-field v-model="email" label="Email do administrador" outlined hide-details="auto" />
                                    </v-col>

                                    <v-col cols="12" class="mb-2">
                                        <v-text-field v-model="password" :type="showPassword ? 'text' : 'password'" label="Senha" outlined hide-details="auto" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" />
                                    </v-col>

                                    <v-col cols="12" class="mt-2">
                                        <v-btn large color="primary darken" width="100%" type="submit">
                                            Entrar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "LoginView",
    data() {
        return {
            logged: false,
            customer_email: "",
            email: "",
            password: "",
            showPassword: false,
        };
    },
    mounted() {
        const token = Api.getToken();
        if (token) this.logged = true;
    },

    methods: {
        async login() {
            const response = await Api.login(this.email, this.password, this.remember, true, this.customer_email);

            if (response.error) {
                this.$toast('error', response.message);
                return
            }

            this.$router.push("/");
        }
    },
};
</script>

<style scoped>
.login_container {
    display: flex;
    align-items: start;
    justify-content: center;
    height: 100%;
}

.login_background_pattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.login_container {
    background: linear-gradient(-45deg, #00630d, #00630d, #00fc22, #262626);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100vh;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
</style>