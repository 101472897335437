<template>
    <v-dialog v-model="dialog" width="720" scrollable>
        <v-card>
            <v-card-title>
                Agendamento de envio
            </v-card-title>

            <v-card-subtitle>
                Envie mensagens individualmente por aqui. Você pode disparar imediatamente ou agendar o envio.
            </v-card-subtitle>

            <v-card-text>
                <v-row class="pt-1">
                    <v-col cols="12" lg="8" md="8" sm="12">
                        <v-row>
                            <v-col cols="4">
                                <v-text-field v-model="country_code" outlined label="Cód País" hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field v-model="object.to" label="Número destino" placeholder="Insira um número com DDI e DDD, exemplo: 5511955555555" outlined hide-details="auto" />
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" lg="4" md="4" sm="12">
                        <v-select clearable v-model="object.chip" item-text="name" item-value="_id" :items="chips" label="Chip de envio" outlined hide-details="auto"></v-select>
                    </v-col>

                    <v-col cols="12">
                        <v-select clearable v-model="object.type" item-text="name" item-value="id" :items="types" label="Tipo de conteúdo" outlined hide-details="auto"></v-select>
                    </v-col>

                    <v-col cols="12" v-if="object.type == 'flow'">
                        <v-select clearable v-model="object.flow" item-text="name" item-value="_id" :items="flows" label="Fluxo" outlined hide-details="auto"></v-select>
                    </v-col>

                    <v-col cols="12" v-if="object.type == 'text'">
                        <v-textarea v-model="object.message" rows="4" label="Texto da mensagem" placeholder="Escreva aqui a mensagem de texto que será enviada." outlined hide-details="auto" messages="Mensagens do tipo texto serão enviadas, mas só criarão uma nova conversa na plataforma após a resposta do contato (para evitar que o chatbot interfira em conversas pessoais iniciadas por você diretamente no celular)" />
                    </v-col>

                    <v-col cols="12">
                        <label class="mb-2"><b>Quando a mensagem deverá ser enviada?</b></label>

                        <v-datetime-picker label="Data e hora do envio" v-model="object.scheduledTo" date-format="dd/MM/yyyy" :datePickerProps="{ locale: 'pt-BR' }" :timePickerProps="{ format: '24hr' }" :textFieldProps="{ outlined: true }">
                            <template slot="dateIcon">
                                <v-icon>mdi-calendar</v-icon>
                            </template>

                            <template slot="timeIcon">
                                <v-icon>mdi-clock-outline</v-icon>
                            </template>

                            <template slot="actions" slot-scope="{ parent }">
                                <v-btn color="error" outlined @click.native="parent.clearHandler">Cancelar</v-btn>
                                <v-btn color="primary" @click="parent.okHandler">OK</v-btn>
                            </template>
                        </v-datetime-picker>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeDialog">Cancelar</v-btn>
                <v-btn color="primary" text @click="createNew" :loading="loading">Salvar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Api from '@/lib/Api';
import { emitToastr, onlyNumbers } from '@/lib/Utils';

export default {
    props: {
        value: Boolean,
        chips: Array,
        phone: String,
        allMessage: Object,
        callback: Function
    },
    data() {
        return {
            loading: false,
            object: {},
            flows: [],
            country_code: '55',
            types: [
                { id: 'text', name: 'Texto' },
                { id: 'flow', name: 'Fluxo' },
            ]
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    watch: {
        dialog: {
            handler() {
                if (this.dialog) {
                    if (!this.allMessage) {
                        this.object.to = this.phone;
                    }
                    else {
                        let data = this.allMessage
                        if (data.scheduledTo) data.scheduledTo = new Date(data.scheduledTo);
                        this.object = data;
                    }

                    if (this.chips.length == 1) this.object.chip = this.chips[0]._id;
                }
            },
        }
    },
    async mounted () {
        this.flows = [];
        const resp = await Api.getFlowsByUser();
        if (!resp.error) this.flows = resp.message;
    },
    methods: {
        clear() {
            this.loading = false;
        },

        closeDialog() {
            this.clear();
            this.dialog = false;
        },

        async createNew() {
            if (!this.object.chip) return emitToastr("error", "Chip de envio necessário");
            if (!this.object.to) return emitToastr("error", "Número de destino necessário");
            if (!this.object.scheduledTo) return emitToastr("error", "Data e hora do agendamento necessárias");

            if (!onlyNumbers(this.object.to)) return emitToastr("error", "Número de telefone inválido.");
            if (!onlyNumbers(this.country_code)) return emitToastr("error", "Código do país inválido.");

            if (this.object.type == 'text' && !this.object.message) return emitToastr("error", "Texto da mensagem necessário");
            if (this.object.type == 'flow' && !this.object.flow) return emitToastr("error", "Fluxo necessário");

            this.loading = true;
            let resp;

            this.object.to = this.country_code + this.object.to;

            if (this.object._id) {
                resp = await Api.updateScheduledMessage(this.object);
            }
            else {
                resp = await Api.createScheduledMessage(this.object);
            }

            this.loading = false;

            if (!resp.error) {
                this.object = { to: "", message: "" };
                emitToastr("success", "Agendamento cadastrado com sucesso.");
                if (this.callback) this.callback();
                this.closeDialog();
            }
        },
    }
};
</script>

<style></style>