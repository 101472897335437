<template>
    <div class="menu-page">
        <v-card>
            <v-card-title>

            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-btn color="primary" @click.native="dialogCreate = true">Importar nova audiência</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="objects">

                            <template v-slot:[`item.created`]="{ item }">
                                {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' -') }}
                            </template>

                            <template v-slot:[`item.people`]="{ item }">
                                {{ item.people.length }}
                            </template>

                            <template v-slot:[`item.props`]="{ item }">
                                {{ item.props && item.props.length && item.props.length > 0 ? item.props.join(' | ') : '' }}
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="mr-4" medium color="error" @click="object = item; dialog = true" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>Apagar</span>
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon medium color="primary" @click="object = item; dialogCreate = true" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>



        </v-card>

        <v-dialog v-model="dialogCreate" max-width="1200">
            <v-card class="pb-2" color="background">
                <v-card-title>
                    Cadastrar ou editar audiência
                </v-card-title>

                <v-card-subtitle class="mb-4">
                    Audiências são grupos de contatos (números de telefone) para os quais você pode enviar mensagens em massa. Inicialmente a audiência é criada a partir da importação de uma planilha.
                </v-card-subtitle>

                <v-card-text>
                    <v-row>

                        <v-col cols="12" lg="6" md="6" sm="12">
                            <v-file-input dense accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" label="Carregue aqui a planilha com a sua audiência" v-model="fileToUpload"></v-file-input>
                            <v-btn v-if="fileToUpload" @click="importResults(false)" large color="primary" class="ml-6">Analisar planilha</v-btn>
                        </v-col>


                        <v-col cols="12" lg="6" md="6" sm="12" v-if="object.people.length > 0">
                            <v-text-field v-model="object.name" label="Nome da audiência" placeholder="Insira um nome que represente esse público" outlined hide-details="auto" />
                        </v-col>



                        <v-col cols="12" lg="6" md="6" sm="12" v-if="object.people.length > 0">
                            <v-text-field v-model="object.people.length" :readonly="true" label="Contatos válidos" outlined hide-details="auto" />
                        </v-col>

                        <v-col cols="12" lg="6" md="6" sm="12" v-if="object.people.length > 0">
                            <v-text-field v-model="object.props" :readonly="true" label="Propriedades reconhecidas" outlined hide-details="auto" />
                        </v-col>

                        <v-col cols="12" sm="12" v-if="object.people.length > 0">
                            <v-switch inset v-model="object.create_contacts" label="Cadastrar audiência como contatos?"></v-switch>
                        </v-col>

                        <v-col cols="12">
                            <span class="primary--text title">COMO IMPORTAR A PLANILHA?</span>
                            <br>
                            <div>
                                <span><b>Campo obrigatório: <span class="primary--text">telefone</span>. É necessário ter uma coluna na planilha de nome "telefone" sem aspas, coluna essa com os números de telefone dos contatos</b></span>
                            </div>
                            <div><span><span class="primary--text"><b>-telefone </b></span>número de Whatsapp do contato. Use DDI e DDD, somente números, sem espaços. Exemplo: 5511955555555</span>
                            </div>
                            <br>
                            <span>Quaisquer outras colunas poderão ser utilizadas como variáveis nas mensagens. Exemplo: se existir uma coluna <span class="primary--text">nome</span> com os nomes das pessoas, a mensagem poderá trazer os nomes dinamicamente com a variável <span
                                    class="primary--text">{nome}</span>. Obs: se utilizar colunas com outras informações, preencha as células para todos os contatos, caso contrário a mensagem poderá ir incompleta para quem estiver sem a informação cadastrada.</span>
                            <br>
                            <b>Faça o download da planilha modelo <a href="/modelo_audiencia.xlsx">clicando aqui</a>, depois preencha a partir dela.</b>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="d-flex flex-wrap">
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" color="primary" outlined @click.native="getObjects(); dialogCreate = false">Cancelar</v-btn>
                    <v-btn class="my-1" color="primary" v-if="object.people.length > 0" @click.native="createNew" :loading="loading">Salvar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog overlay-opacity="0.5" overlay- v-model="dialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar audiência
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar a audiência <b>{{ object.name }}</b>?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialog = false; deleteObject(object)">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "AudienciasView",

    data() {
        return {
            objects: [],
            showAlert: false,
            fileToUpload: null,
            object: { name: "", people: [], props: [], create_contacts: true },
            selectedItem: {},
            dialog: false,
            dialogCreate: false,
            loading: false,
            headers: [
                { text: 'Criação', value: 'created' },
                { text: 'Nome', value: 'name' },
                { text: "Quantidade de contatos", value: "people", sortable: false },
                { text: 'Variáveis', value: 'props' },
                { text: "Ações", value: "actions", sortable: false }
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        },
    },

    async mounted() {
        await this.getObjects();
    },

    methods: {
        async getObjects() {
            const resp = await Api.getAudienceByUser();

            if (!resp.error && resp.message) {
                this.objects = resp.message;
            }
        },

        async createNew() {
            if (this.object.name == "") return emitToastr("error", "Nome da audiência necessário");


            this.loading = true;
            const resp = await Api.createOrUpdateAudience(this.object);
            this.loading = false;

            if (!resp.error) {
                this.object = { name: "", people: [], props: [] };
                this.dialogCreate = false;
                emitToastr("success", "Audiência cadastrada com sucesso.");
                this.getObjects();
            }
        },

        async importResults(save = false) {
            this.loading = true;
            let resp = await Api.sendAudienceList({}, this.fileToUpload, save);
            this.loading = false;

            if (resp && !resp.error) {
                if (resp.total_count && resp.total_count > 0) {
                    this.object.people = resp.valid;
                    this.object.props = resp.header;
                }

                if (resp.message) {
                    emitToastr("success", resp.message);
                }
            }
        },

        async deleteObject(object) {
            object.filed = true;
            await Api.createOrUpdateAudience(object);
            this.getObjects();
            this.object = { name: "", people: [], props: [] };
        },

    },
};
</script>

<style scoped></style>