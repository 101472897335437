import Api from "@/lib/Api";

export default {
    namespaced: true,
    state: {
        data: {
            open_conversations: { all: 0, chatbot: 0, manual: 0 },
            last_contacts: []
        }
    },
    mutations: {
        setRealTimeData(state, data) {
            state.data = data;
        }
    },
    actions: {
        async load({ commit }, selected_chip) {
            if (!selected_chip) selected_chip = -1;
            const response = await Api.getDashboardRealTimeData(selected_chip || undefined);

            if (response.error) return

            if (response && response.message) {
                commit('setRealTimeData', response.message)
            }
        },

    }
}