<template>
    <div class="menu-page">
        <v-card>
            <v-card-title>
                Mensagens Agendadas
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-btn color="primary" @click.native="object = {}; dialogScheduledMessage = true">Novo agendamento</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="objects" :loading="loading">
                            <template v-slot:[`item.status`]="{ item }">
                                {{ statuses[item.status] || "Agendado" }}
                            </template>

                            <template v-slot:[`item.scheduledTo`]="{ item }">
                                {{ $date.smallFormat(item.scheduledTo) }}
                            </template>

                            <template v-slot:[`item.message`]="{ item }">
                                {{ item.message && item.message.length > 80 ? `${item.message.substring(0, 79)}...` : item.message }} {{ !item.message && item.flow ? 'Fluxo' : '' }}
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex" v-if="item.status == 'scheduled'">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon :disabled="item.user_role == 'owner'" medium class="mr-2" color="error lighten-1" @click="confirmDeleteObject(item)" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>Apagar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon :disabled="item.user_role == 'owner'" medium color="primary" @click="openObject(item)" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="deleteDialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar agendamento
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o agendamento para <b>{{ object.to }}</b>?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="deleteDialog = false; deleteObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <dialog-scheduled-message v-model="dialogScheduledMessage" phone="" :allMessage="object" :chips="chips" :callback="getObjects"></dialog-scheduled-message>
    </div>
</template>

<script>
import Api from "@/lib/Api";
import DialogScheduledMessage from "@/components/DialogScheduledMessage";
import { emitToastr } from "@/lib/Utils";


export default {
    name: "MensagensAgendadasView",

    components: {
        DialogScheduledMessage
    },

    data() {
        return {
            objects: [],
            object: {},
            chips: [],
            statuses: { "sent": "Enviado", "error": "Erro", "scheduled": "Agendado" },
            loading: true,
            dialogScheduledMessage: false,
            deleteDialog: false,
            headers: [
                { text: 'Data e hora', value: 'scheduledTo', sortable: false },
                { text: 'Telefone', value: 'to', sortable: false },
                { text: 'Mensagem', value: 'message', sortable: false },
                { text: 'Status', value: 'status', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    async mounted() {
        await this.getObjects();
        await this.getChips();
    },

    methods: {
        async getObjects() {
            this.loading = true;
            const resp = await Api.getScheduledMessagesByUser();
            this.loading = false;

            if (!resp.error && resp.message) {
                this.objects = resp.message;
            }
        },

        async getChips() {
            const resp = await Api.getChipNumbersByUser();

            if (!resp.error && resp.message) {
                this.chips = resp.message;
            }
        },

        openObject(item) {
            if (item._id && item.to) {
                const ddi = item.to.substring(0, 2);
                if (ddi === "55") item.to = item.to.substring(2);
            }
            this.object = item;
            this.dialogScheduledMessage = true;
        },

        confirmDeleteObject(item) {
            this.object = item;
            this.deleteDialog = true;
        },

        async deleteObject() {
            this.object.filed = true;
            const resp = await Api.updateScheduledMessage(this.object);

            if (!resp.error) {
                this.object = {};
                emitToastr("success", "Agendamento apagado com sucesso.");
                this.getObjects();
            }
        },

    },
};
</script>

<style scoped></style>