import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ptBr from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        current: 'ptBr',
        locales: { ptBr }
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                // vuetify colors
                primary: '#00d61d',
                green1: '#00e320',
                grey1: '#4c4c4c',

                darkbackground: '#ffffff',
                darkbackground2: '#ffffff'
                
            }
        }
    }
});
