import Api from "@/lib/Api";

export default {
    namespaced: true,
    state: {
        open_manual_count: 0
    },
    mutations: {
        setOpenManualCount(state, data) {
            state.open_manual_count = data;
        }
    },
    actions: {
        async load_open_manual_count({ commit }) {
            const response = await Api.getOpenManualConversationCount()

            if (response.error) return

            commit('setOpenManualCount', response.message)
        },
    }
}