<template>
    <div class="menu-page">
        <v-card>
            <v-card-title>
                Departamentos
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-btn color="primary" @click.native="openNew">Novo departamento</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="objects" :loading="loading">
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon :disabled="item.default" medium class="mr-2" color="error lighten-1" @click="confirmDeleteObject(item)" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>Apagar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon :disabled="item.default" medium color="primary" @click="openObject(item)" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" scrollable max-width="600" persistent>
            <v-card>
                <v-card-title>
                    Departamento
                </v-card-title>
                <v-card-subtitle>
                    Crie ou modifique departamentos para organizar os atendimentos de forma simplificada e eficiente. Os atendentes só poderão ver conversas de seus departamentos.
                </v-card-subtitle>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field class="pt-1" v-model="object.name" label="Nome do departamento" outlined hide-details="auto" />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field class="pt-1" v-model="object.description" label="Descrição (opcional)" outlined hide-details="auto" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click.native="dialog = false">Cancelar</v-btn>
                    <v-btn color="primary" @click.native="save" :loading="loading">Salvar</v-btn>
                </v-card-actions>

            </v-card>

        </v-dialog>


        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="deleteDialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar departamento
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o departamento <b>{{ object.name }}</b>?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="deleteDialog = false; deleteObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
//import { emitToastr } from "@/lib/Utils";


export default {
    name: "MensagensRapidasView",

    data() {
        return {
            objects: [],
            object: {},
            loading: true,
            dialog: false,
            deleteDialog: false,
            headers: [
                { text: 'Nome', value: 'name', sortable: false },
                { text: 'Descrição', value: 'description', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    async mounted() {
        await this.getObjects();
    },

    methods: {
        async getObjects() {
            this.loading = true;
            const resp = await Api.getDepartmentsByUser();
            this.loading = false;

            if (!resp.error && resp.message) {
                this.objects = resp.message;
            }
        },

        openNew() {
            this.object = {};
            this.dialog = true;
        },

        confirmDeleteObject(item) {
            this.object = item;
            this.deleteDialog = true;
        },

        async deleteObject() {
            this.object.filed = true;
            const resp = await Api.createOrUpdateDepartment(this.object);
            if (!resp.error && resp.message) {
                emitToastr("success", "Departamento apagado com sucesso");
                this.object = {};
                this.getObjects();
            }
        },

        openObject(item) {
            this.object = item;
            this.dialog = true;
        },

        async save() {
            if (!this.object.name) return emitToastr("error", "O nome é um campo necessário");

            this.loading = true;
            const resp = await Api.createOrUpdateDepartment(this.object);
            this.loading = false;

            if (!resp.error) {
                emitToastr("success", "Departamento salvo com sucesso");
                this.getObjects();
                this.dialog = false;
            }
        }
    },
};
</script>

<style scoped></style>