<template>
    <div class="menu-page">
        <v-card>
            <v-card-title>
                Minha Equipe
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-btn color="primary" @click.native="openNew">Novo atendente</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="objects" :loading="loading">
                            <template v-slot:[`item.user_role`]="{ item }">
                                {{ types_dictionary[item.user_role] }}
                            </template>

                            <template v-slot:[`item.departments`]="{ item }">
                                {{ getDepartmentsNames(item.departments) }}
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon :disabled="item.user_role == 'owner'" medium class="mr-2" color="error lighten-1" @click="confirmDeleteObject(item)" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>Apagar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon :disabled="item.user_role == 'owner'" medium color="primary" @click="openObject(item)" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" scrollable max-width="600" persistent>
            <v-card>
                <v-card-title>
                    Gerenciar equipe
                </v-card-title>
                <v-card-subtitle>
                    <div>Gerentes podem ver e editar tudo, exceto a área de assinatura.</div>
                    <div>Atendentes podem apenas interagir com contatos e e conversas.</div>
                </v-card-subtitle>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field class="pt-1" v-model="object.name" label="Nome" outlined hide-details="auto" />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field class="pt-1" v-model="object.email" label="E-mail" outlined hide-details="auto" />
                        </v-col>

                        <v-col cols="12">
                            <v-select outlined v-model="object.user_role" :items="types" item-text="name" item-value="id" label="Tipo de usuário" hide-details="auto"></v-select>
                        </v-col>

                        <v-col cols="12" v-if="object.user_role == 'user'">
                            <v-select outlined chips small-chips multiple v-model="object.departments" :items="departments" item-text="name" item-value="_id" label="Departamentos" hide-details="auto"
                                :message="`${object.name} é atendente e só poderá ver e interagir com mensagens de seus departamentos. Gerentes podem interagir com todos os departamentos.`">
                                <template #selection="{ item }">
                                    <v-chip small color="primary">{{ item.name }}</v-chip>
                                </template>
                            </v-select>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field class="pt-1" type="password" v-model="object.password" label="Senha" outlined hide-details="auto" messages="O usuário poderá trocar a senha depois de acessar" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click.native="dialog = false">Cancelar</v-btn>
                    <v-btn color="primary" @click.native="save" :loading="loading">Salvar</v-btn>
                </v-card-actions>

            </v-card>

        </v-dialog>


        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="deleteDialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar conta
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar a conta de <b>{{ object.name }}</b>?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="deleteDialog = false; deleteObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
//import { emitToastr } from "@/lib/Utils";


export default {
    name: "MensagensRapidasView",

    data() {
        return {
            objects: [],
            object: {},
            departments: [],
            loading: true,
            dialog: false,
            deleteDialog: false,
            types_dictionary: {
                "owner": "Proprietário da conta",
                "manager": "Gerente",
                "user": "Atendente"
            },
            types: [
                { id: 'manager', name: 'Gerente' },
                { id: 'user', name: 'Atendente' },
            ],
            headers: [
                { text: 'Nome', value: 'name', sortable: false },
                { text: 'E-mail', value: 'email', sortable: false },
                { text: 'Permissões', value: 'user_role', sortable: false },
                { text: 'Departamentos', value: 'departments', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    async mounted() {
        await this.getObjects();
        await this.getDepartments();
    },

    methods: {
        async getObjects() {
            this.loading = true;
            const resp = await Api.getTeamUsers();
            this.loading = false;

            if (!resp.error && resp.message) {
                this.objects = resp.message;
            }
        },

        async getDepartments() {
            const resp = await Api.getDepartmentsByUser();

            if (!resp.error && resp.message) {
                this.departments = resp.message;
            }
        },

        getDepartmentsNames(departments) {
            let names = "";
            const user_deparments = this.departments.filter(d => departments.includes(d._id.toString()));

            user_deparments.forEach(d => names += `, ${d.name}`);
            if (names.length > 0) names = names.substring(2);

            return names;
        },

        openNew() {
            this.object = {};
            this.dialog = true;
        },

        confirmDeleteObject(item) {
            this.object = item;
            this.deleteDialog = true;
        },

        async deleteObject() {
            this.object.filed = true;
            this.save();
        },

        openObject(item) {
            this.object = item;
            this.dialog = true;
        },

        async save() {
            if (!this.object.name) return emitToastr("error", "Todos os campos são obrigatórios");
            if (!this.object.email) return emitToastr("error", "Todos os campos são obrigatórios");
            if (!this.object.user_role) return emitToastr("error", "Todos os campos são obrigatórios");

            if (this.object.user_role == 'user') {
                if (!this.object.departments || this.object.departments.length == 0) return emitToastr("error", "É necessário definir pelo menos 1 departamento");
            }

            if (!this.object._id) {
                if (!this.object.password) return emitToastr("error", "Todos os campos são obrigatórios");
                if (this.object.password.length < 6) return emitToastr("error", "A senha precisa ter pelo menos 6 dígitos");
            }

            if (!this.object._id) {
                this.loading = true;
                const resp = await Api.createTeamUser(this.object);
                this.loading = false;

                if (!resp.error) {
                    emitToastr("success", "Atendente criado com sucesso");
                    this.getObjects();
                    this.dialog = false;
                }
            }
            else {
                this.loading = true;
                const resp = await Api.updateUser(this.object);
                this.loading = false;

                if (!resp.error) {
                    emitToastr("success", "Atendente atualizado com sucesso");
                    this.getObjects();
                    this.dialog = false;
                }
            }
        }
    },
};
</script>

<style scoped></style>