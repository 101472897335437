<template>
    <div class="menu-page">
        <v-card class="">
            <v-card-title>
                Contatos
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="12" class="d-flex">
                        <v-text-field class="mb-6" v-model="search" append-icon="mdi-magnify" label="Busque por nome ou e-mail" clearable outlined single-line hide-details @input="searchOnServer" style="max-width: 500px;"></v-text-field>
                    </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="objects" :options.sync="options" :server-items-length="totalObjects" :loading="loading">

                    <template v-slot:[`item.created`]="{ item }">
                        {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' -') }}
                    </template>

                    <template v-slot:[`item.name`]="{ item }">
                        {{ item.override_name || item.name }}
                    </template>

                    <template v-slot:[`item.user_role`]="{ item }">
                        <span v-if="item.user_role == 'owner'"> Proprietário(a)</span>
                        <span v-if="item.user_role == 'manager'"> Gerente</span>
                        <span v-if="item.user_role == 'user'"> Atendente</span>
                    </template>

                    <template v-slot:[`item.account.subscription_status`]="{ item }">
                        <span v-if="item.account.subscription_status == 'active'"> <v-chip small color="success">Ativo</v-chip></span>
                        <span v-if="item.account.subscription_status == 'canceled'"> <v-chip small color="error lighten-1">Cancelado</v-chip></span>
                    </template>

                    <template v-slot:[`item.account.custom_negotiation`]="{ item }">
                        <span v-if="item.account.custom_negotiation"> Sim</span>
                        <span v-if="!item.account.custom_negotiation"> Não</span>
                    </template>

                    <template v-slot:[`item.profile_picture`]="{ item }">
                        <v-avatar size="60" class="my-2">
                            <v-img :src="item.profile_picture" v-if="item.profile_picture" />
                            <div v-else class="w-full h-full primary d-flex justify-center">
                                <v-icon color="white" style="font-size:60px">mdi-account</v-icon>
                            </div>
                        </v-avatar>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mr-2" medium color="primary" @click="selectObject(item); dialog2 = true" v-bind="attrs" v-on="on">mdi-lock-reset</v-icon>
                            </template>
                            <span>Mudar senha</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon class="mr-2" medium color="primary" @click="selectObject(item); dialog = true" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" scrollable max-width="600" persistent>
            <v-card>
                <v-card-title>
                    Gerenciar conta
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field class="pt-1" type="number" v-model="object.account.max_channels" label="Limite de Canais" outlined hide-details="auto" />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field class="pt-1" type="number" v-model="object.account.max_users" label="Limite de Usuários" outlined hide-details="auto" />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field class="pt-1" type="number" v-model="object.account.max_flows" label="Limite de Fluxos" outlined hide-details="auto" />
                        </v-col>

                        <v-col cols="12">
                            <v-switch inset class="mt-0" v-model="object.account.campaigns_enabled" label="Campanhas Habilitadas?"></v-switch>
                        </v-col>

                        <v-col cols="12">
                            <v-switch inset class="mt-0" v-model="object.account.custom_negotiation" label="Negociação customizada?" messages="Ative caso a conta receba alguma cortesia a mais do que seu plano permite (como um canal a mais). Dessa forma a cortesia não será perdida na renovação."></v-switch>
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2" color="error" outlined @click.native="dialog = false">Cancelar</v-btn>
                    <v-btn class="mb-2" color="primary" @click.native="save" :loading="loading">Salvar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog2" scrollable max-width="600" persistent>
            <v-card>
                <v-card-title>
                    Mudar senha
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field class="pt-2" v-model="newPassword" label="Nova senha" outlined hide-details="auto" :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" />
                        </v-col>

                        <v-col cols="6">
                            <v-text-field class="pt-2" v-model="confirmNewPassword" label="Confirmar nova senha" outlined hide-details="auto" :type="showPassword ? 'text' : 'password'" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2" color="error" outlined @click.native="newPassword = ''; confirmNewPassword = ''; dialog2 = false">Cancelar</v-btn>
                    <v-btn class="mb-2" color="primary" @click.native="changePassword" :loading="loading">Mudar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

let timeout = null;

export default {
    name: "AdminClientesView",

    data() {
        return {
            objects: [],
            object: { account: {} },
            totalObjects: 0,
            search: "",
            loading: true,
            dialog: false,
            dialog2: false,
            selectedContact: null,
            newPassword: '',
            confirmNewPassword: '',
            showPassword: false,
            options: { itemsPerPage: 10 },
            headers: [
                { text: 'Foto', value: 'profile_picture', sortable: false },
                { text: 'Nome', value: 'name', sortable: false },
                { text: "E-mail", value: "email", sortable: false },
                { text: "Tipo", value: "user_role", sortable: false },
                { text: "Plano", value: "account.plan_id", sortable: false },
                { text: "Status plano", value: "account.subscription_status", sortable: false },
                { text: "Plano customizado", value: "account.custom_negotiation", sortable: false },
                { text: 'Criação', value: 'created', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    watch: {
        options: {
            handler() {
                this.getObjects(this.options);
            },
            deep: true,
        },
    },

    async mounted() {

    },

    methods: {
        selectObject(item) {
            this.object = JSON.parse(JSON.stringify(item))
        },

        async changePassword() {
            if (!this.newPassword || this.newPassword.length < 6) return emitToastr("error", "A senha deve ter pelo menos 6 dígitos");
            if (this.confirmNewPassword != this.newPassword) return emitToastr("error", "As senhas não conferem");

            this.loading = true;
            const resp = await Api.changeCustomerPassword(this.object._id, this.newPassword);
            this.loading = false;

            if (!resp.error && resp.message) {
                emitToastr("success", "Senha alterada com sucesso!");
                this.newPassword = '';
                this.confirmNewPassword = '';
                this.showPassword = false;
                this.dialog2 = false;
            }
        },

        async getObjects(options) {
            this.loading = true;
            const resp = await Api.getCustomers(options);
            this.loading = false;

            if (!resp.error && resp.message) {
                this.objects = resp.message.data;
                this.totalObjects = resp.message.total;
            }
        },

        async searchOnServer() {
            if (timeout) clearTimeout(timeout);
            this.loading = true;

            timeout = setTimeout(() => {
                this.options.page = 1;
                this.options.query = this.search;
                this.loading = false;
                this.getObjects(this.options);
            }, 1000);
        },

        async save() {
            if (isNaN(this.object.account.max_channels)) return emitToastr("error", "Quantidade de canais inválida!");
            if (isNaN(this.object.account.max_users)) return emitToastr("error", "Quantidade de usuários inválida!");
            if (isNaN(this.object.account.max_flows)) return emitToastr("error", "Quantidade de fluxos inválida!");

            this.loading = true;
            const resp = await Api.updateAccount(this.object.account);
            this.loading = false;
            if (!resp.error && resp.message) {
                emitToastr("success", "Conta atualizada com sucesso!");
                this.search = '';
                this.dialog = false;
                this.getObjects();
            }
        },
    },
};
</script>

<style scoped></style>