<template>
    <div class="menu-page">
        <v-card>
            <v-card-title>Ajuda</v-card-title>
            <v-card-subtitle>Nesta seção você poderá encontrar vídeos completos sobre o funcionamento da plataforma. Novos vídeos serão adicionados semanalmente, não deixe de conferir.</v-card-subtitle>


            <v-card-text>
                <v-card-title>1. Funcionamento geral da plataforma</v-card-title>
                <v-card-subtitle>O vídeo mais importante. Passa por todas as áreas e configurações necessárias para a perfeita configuração da sua central de atendimento</v-card-subtitle>

                <v-responsive :aspect-ratio="16/9" width="90%" max-width="1000">
                    <iframe src="https://www.youtube.com/embed/OIS902G1FEQ?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen> </iframe>
                </v-responsive>

            </v-card-text>

            <v-card-text>
                <v-card-title>2. Tipos de chatbot. Qual devo escolher?</v-card-title>
                <v-card-subtitle>Chatbot de IA, chatbot de fluxo ou ambos juntos? Como entender qual tipo vai melhor lhe atender</v-card-subtitle>

                <v-responsive :aspect-ratio="16/9" width="90%" max-width="1000">
                    <iframe src="https://www.youtube.com/embed/Z14Pszn3hlI?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen> </iframe>
                </v-responsive>

            </v-card-text>

            <v-card-text>
                <v-card-title>3. Criando chatbots incríveis</v-card-title>
                <v-card-subtitle>Aprenda a criar chatbots profissionais e resolva qualquer necessidade de atendimento usando os recursos certos na plataforma</v-card-subtitle>

                <v-responsive :aspect-ratio="16/9" width="90%" max-width="1000">
                    <iframe src="https://www.youtube.com/embed/ovI823xIX_Y?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen> </iframe>
                </v-responsive>

            </v-card-text>

            <v-card-text>
                <v-card-title>4. Construtor de fluxo e seus elementos</v-card-title>
                <v-card-subtitle>Aprenda tudo sobre o construtor de fluxos da InteligênciaBot e saiba tirar o máximo proveito de seus poderosos recursos</v-card-subtitle>

                <v-responsive :aspect-ratio="16/9" width="90%" max-width="1000">
                    <iframe src="https://www.youtube.com/embed/mowb8hhJu2I?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen> </iframe>
                </v-responsive>

            </v-card-text>
        </v-card>
    </div>
</template>

<script>
//import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";
//import { emitToastr } from "@/lib/Utils";


export default {
    name: "AjudaView",

    data() {
        return {

        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    async mounted() {

    },

    methods: {
    }
};
</script>

<style scoped>
.video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 ratio (most common) */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
}

.video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
</style>