<template>
    <div class="custom-page" @click="emojiDialog = false">
        <v-row style="margin: 0;">
            <v-col cols="12" lg="5" md="5" sm="12" class="pa-0">
                <v-app-bar shrink-on-scroll height="120" dark class="flex-grow-0">
                    <div class="filter-bar">
                        <div class="filter-item one-line">
                            <b>Atendimento:</b> <span>{{ getSelectedTypes }}</span>
                        </div>
                        <div class="filter-item one-line">
                            <b>Departamentos:</b> <span>{{ getSelectedDepartments }}</span>
                        </div>
                        <div class="filter-item one-line">
                            <b>Tags:</b> <span>{{ getSelectedTags }}</span>
                        </div>
                        <div v-if="pre_filter?.situation?.id === 'waiting_response'" class="filter-item one-line">
                            <v-chip x-small label class="error lighten-1">Apenas não respondidas</v-chip>
                        </div>
                        <div class="filter-item mb-1" style="display: flex; width: 100%;">
                            <v-spacer></v-spacer>
                            <v-btn small dark outlined color="error" class="mr-4" @click="dialogFinishConversations = true">Finalizar em massa</v-btn>
                            <v-btn v-if="can_clear" small dark outlined color="error" class="mr-4" @click="clearFilters">Limpar filtros</v-btn>
                            <v-btn small dark outlined color="primary" @click="filter_sheet = true">Filtros</v-btn>
                        </div>
                    </div>
                </v-app-bar>
                <v-btn block class="primary" small @click="startNewConversationDialog">Nova conversa</v-btn>
                <div class="conversation-list d-flex" style="width: 100%;">
                    <v-list three-line color="rgb(250, 250, 250)" style="width: 100%;">
                        <v-list-item-group color="primary" style="width: 100%;">

                            <v-list-item v-if="!loading && objects.length == 0" key="9999999" class="px-5" style="width: 100%;">
                                <v-list-item-content>
                                    <v-list-item-title style="font-weight: 500;" class="mb-2">Sem
                                        conversas...</v-list-item-title>
                                    Tente utilizar outro filtro ou aguarde por novas mensagens
                                </v-list-item-content>
                            </v-list-item>

                            <template v-for="item in objects">
                                <v-list-item :key="item?._id" class="cursor-pointer px-5" @click="getMessages(item)">
                                    <v-list-item-avatar size="55">
                                        <v-img v-if="item.contact?.profile_picture" :src="item.contact?.profile_picture"></v-img>
                                        <div v-else class="w-full h-full primary d-flex justify-center">
                                            <v-icon color="white" style="font-size:55px">mdi-account</v-icon>
                                        </div>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title style="font-weight: 500;">
                                            <v-icon size="18" v-if="item.waiting_response" color="error lighten-1" class="response-badge">mdi-message-text-clock-outline</v-icon>
                                            {{ item.contact?.override_name || item.contact?.name || 'Sem nome' }}
                                            <v-chip small v-if="!item.is_manual" color="primary" class="mr-1" label><v-icon small left>{{ item.chatbot_type == 'flow_chatbot' ?
                                                'mdi-sitemap-outline' : 'mdi-robot-outline'
                                                    }}</v-icon>Chatbot</v-chip>
                                            <v-chip small v-if="item.is_manual && item.is_new" color="primary" class="mr-1" label>Nova</v-chip>
                                            <v-chip small v-if="item.department" label><v-icon small left>mdi-briefcase-outline</v-icon>{{ item.department.name
                                                }}</v-chip>
                                        </v-list-item-title>

                                        <v-list-item-title v-if="item.contact?.tags?.length > 0">
                                            <v-chip x-small class="mr-1" v-for="(tag, index_tag) in item.contact?.tags" :color="tags_dictionary[tag]?.color" :key="`tag_${index_tag}`" label v-show="tags_dictionary[tag]">{{ tags_dictionary[tag]?.name }}</v-chip>
                                        </v-list-item-title>
                                        <message-preview :message="item.last_message?.message" :status="item.last_message?.status" :origin="item.last_message?.origin" :type="item.last_message?.type" />
                                    </v-list-item-content>
                                </v-list-item>
                            </template>

                            <div class="d-flex align-center justify-center mt-2" v-if="button_load_more">
                                <v-btn class="mb-4" small color="primary" @click="options.limit += 15; getConversations(options, false)">Carregar mais</v-btn>
                            </div>
                        </v-list-item-group>
                    </v-list>
                </div>
            </v-col>

            <dialog-or-column v-model="showConversation" class="pa-0 d-flex flex-column">
                <v-app-bar height="120" class="flex-grow-0" v-show="selectedConversation?._id">
                    <v-btn v-if="isMobile" icon large class="mr-1" @click="showConversation = false"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <v-avatar size="60" class="my-2 cursor-pointer" @click="openContact(selectedConversation?.contact?._id)">
                        <v-img :src="selectedConversation?.contact?.profile_picture" v-if="selectedConversation?.contact?.profile_picture" />
                        <div v-else class="w-full h-full primary d-flex justify-center">
                            <v-icon color="white" style="font-size:60px">mdi-account</v-icon>
                        </div>
                    </v-avatar>
                    <div class="contact-info cursor-pointer">
                        <span class="contact-name">
                            <v-icon size="20" v-if="selectedConversation.waiting_response" color="error lighten-1" class="response-badge mr-1">mdi-message-text-clock-outline</v-icon>
                            <span @click="openContact(selectedConversation?.contact?._id)">{{
                                selectedConversation?.contact?.override_name || selectedConversation?.contact?.name ||
                                "Contato" }}
                                <small v-if="selectedConversation?.is_manual">(Atendimento humano)</small></span>
                            <v-chip class="ml-2 cursor-pointer" small v-if="selectedConversation.department" label @click="departmentDialog = true"><v-icon small left>mdi-briefcase-outline</v-icon>{{
                                selectedConversation.department.name }}</v-chip>
                        </span>
                        <div v-if="selectedConversation.tags?.length > 0">
                            <v-chip x-small class="mr-1" v-for="(tag, index_tag) in selectedConversation.tags" :color="tags_dictionary[tag]?.color" :key="`tag_${index_tag}`" label v-show="tags_dictionary[tag]">{{ tags_dictionary[tag]?.name }}</v-chip>
                        </div>
                        <span class="contact-phone" @click="openContact(selectedConversation?.contact?._id)">{{ typing ?
                            'Digitando...' : selectedConversation?.contact?.phone }}</span>
                    </div>

                    <v-spacer></v-spacer>

                    <v-menu offset-y v-if="selectedConversation?._id">
                        <template #activator="{ on }">
                            <v-btn icon v-on="on" color="black">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item class="cursor-pointer" @click="openContact(selectedConversation?.contact?._id)">
                                <v-list-item-title>
                                    <v-icon size="25" class="mr-1" color="primary">mdi-account-details-outline</v-icon>
                                    Dados do contato
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="cursor-pointer" @click="selectedConversation.waiting_response = false; changeConversationData()">
                                <v-list-item-title>
                                    <v-icon size="25" class="mr-1" color="primary">mdi-check-circle-outline</v-icon>
                                    Marcar como respondida
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="cursor-pointer" @click="dialogScheduledMessage = true">
                                <v-list-item-title>
                                    <v-icon size="25" class="mr-1" color="primary">mdi-calendar-clock-outline</v-icon>
                                    Agendar mensagem
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="cursor-pointer" @click="tagsDialog = true">
                                <v-list-item-title>
                                    <v-icon size="25" class="mr-1" color="primary">mdi-tag-multiple-outline</v-icon>
                                    Atribuir tags
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="cursor-pointer" @click="flowsDialog = true">
                                <v-list-item-title>
                                    <v-icon size="25" class="mr-1" color="primary">mdi-sitemap-outline</v-icon>
                                    Transferir para fluxo
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="cursor-pointer" v-if="!selectedConversation.finished" @click="departmentDialog = true">
                                <v-list-item-title>
                                    <v-icon size="25" class="mr-1" color="primary">mdi-briefcase-arrow-left-right-outline</v-icon>
                                    Transferir departamento
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="cursor-pointer" @click.native="resumeConversation(selectedConversation)" v-if="selectedConversation.finished">
                                <v-list-item-title>
                                    Retomar atendimento
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="cursor-pointer" @click="setManual(selectedConversation)" v-if="!selectedConversation.is_manual && !selectedConversation.finished">
                                <v-list-item-title>
                                    <v-icon size="25" class="mr-1" color="primary">mdi-human-greeting</v-icon>
                                    Atender como humano
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="cursor-pointer" @click="finishConversation(selectedConversation)" v-if="!selectedConversation.finished">
                                <v-list-item-title>
                                    <v-icon size="25" class="mr-1" color="primary">mdi-check-outline</v-icon>
                                    Finalizar atendimento
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-app-bar>

                <div class="conversation-container flex-grow-1" :style="{ backgroundImage: 'url(' + require('@/assets/whatsapp-background.png') + ')', backgroundRepeat: 'repeat' }">
                    <div class="messages-container flex-grow-1 pa-4" ref="dialogRef">
                        <div class="d-flex justify-center">
                            <v-btn class="mb-4" v-if="rendered_messages.length < messages.length" small color="primary" @click="loadMoreMessages">Carregar mais</v-btn>
                        </div>

                        <div v-for="(item, message_index) in rendered_messages" :class="`fade chat-item ${item.origin == 'customer' ? 'customer' : 'chatbot'}-message ${item.deleted ? 'deleted-message' : ''}`" :key="`message_${message_index}`" :id="`message_${message_index}`" @click="selectedChatItem = item">
                            <div v-if="item.deleted" style="font-size: 12px" class="mb-2"><i>(Essa mensagem foi apagada)</i></div>

                            <div v-if="item.user">
                                <b>{{ users[item.user] || 'Colaborador' }}</b>
                            </div>

                            <div class="reply-container" v-if="item.reply_message?.type == 'text'">
                                <v-icon small>mdi-reply-outline</v-icon>
                                {{ item.reply_message.message }}
                            </div>

                            <audio controls class="mb-2" v-if="item.type == 'audio' && item.media_url">
                                <source :src="item.media_url" type="audio/mpeg">
                                Seu navegador não suporte player de audio.
                            </audio>
                            <span v-if="item.type == 'audio' && item.origin == 'customer'">[TRANSCRIÇÃO]: </span>

                            <video class="video-container" v-if="item.type == 'video'" controls="controls">
                                <source :src="item.media_url" type="video/mp4" />
                                <!--Suportado em IE9, Chrome 6 e Safari 5 -->
                                O seu navegador não suporta a tag vídeo
                            </video>

                            <div v-if="item.type == 'image' || (item.type == 'text' && item.media_url)">
                                <v-img class="cursor-pointer" :src="item.media_url" contain height="auto" max-width="80vw" @click="showImage(item.media_url)"></v-img>
                            </div>

                            <div v-if="item.type == 'document'" class="cursor-pointer pa-2" @click="openFile(item.media_url)">
                                <v-icon size="40">mdi-file-document-outline</v-icon>
                                <div style="font-weight: 400;" class="mt-2">
                                    {{ item.file_name || 'Documento' }}
                                </div>
                            </div>

                            <div v-if="item.canonical_url">
                                <b>{{ item.canonical_url }}</b>
                            </div>

                            <div>
                                {{ item.message }}
                            </div>

                            <div class="d-flex align-center">
                                <v-btn small icon v-if="item.origin != 'customer' && selectedChatItem.platform_message_id == item.platform_message_id && !item.deleted" @click="deleteMessageDialog = true">
                                    <v-icon size="18">mdi-trash-can</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <div class="message-time" v-if="item.created">
                                    {{ $date.smallFormat(item.created) }}
                                    <v-icon v-if="item.origin != 'customer'" size="13" :color="item.status >= 4 || last_read_message_index >= message_index ? 'blue' : 'grey'">mdi-check-all</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-grow-0 command-bar " v-if="selectedConversation.is_manual && !selectedConversation.finished">
                        <div class="d-flex my-4 mr-4 fade flex-grow-1 align-center">
                            <v-btn icon class="mr-1" @click="openMedias" v-show="text == ''">
                                <v-icon>mdi-paperclip</v-icon>
                            </v-btn>

                            <v-btn icon class="mr-1" @click="openRecording" v-show="text == ''">
                                <v-icon>mdi-microphone</v-icon>
                            </v-btn>

                            <v-btn icon class="mr-1" @click.stop="toogleDialogEmoji">
                                <v-icon>mdi-emoticon-happy-outline</v-icon>
                            </v-btn>

                            <div id="exampleInputEmoji" @click.stop="() => { }">
                                <VEmojiPicker v-show="emojiDialog" :style="{ width: '400px', height: '200' }" :showSearch="false" lang="pt-BR" @select="onSelectEmoji" @click.stop="true" />
                            </div>


                            <v-layout column>
                                <v-menu ref="menu" attach=".ref1" top v-model="menu">
                                    <v-list dense style="min-height: 200px; max-height: 200px;width: 90vw;max-width: 800px; overflow-y: auto;">
                                        <v-list-item background-color="white" v-for="(item, index) in filteredItems" :key="index" @click="text = item.message">
                                            <v-list-item-title>{{ item.shortcut }}: {{ item.message.length < 60 ? item.message : item.message.substring(0, 59) + '...' }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-textarea class="ref1" outlined background-color="white" v-model="text" rows="2" v-on:keydown.enter="handleKeyDown" @input="handleInput" no-resize hide-details></v-textarea>
                            </v-layout>

                            <v-btn class="ml-2" fab dark small color="success" @click.native="sendMessage">
                                <v-icon dark>
                                    mdi-send
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>

                </div>
            </dialog-or-column>
        </v-row>

        <v-bottom-sheet dark v-model="filter_sheet">
            <v-list nav dense>
                <v-list-item class="mb-0">
                    <v-list-item-title>Situação do atendimento</v-list-item-title>
                </v-list-item>
                <v-list-item class="mb-0">
                    <v-btn-toggle mandatory v-model="pre_filter.situation" dense color="primary">
                        <v-btn small v-for="(item, index) in conversation_situations" :value="item" :key="index">
                            <v-icon size="18" class="mr-2">{{ item.icon }}</v-icon>
                            <span>{{ item.name }}</span>
                        </v-btn>
                    </v-btn-toggle>
                </v-list-item>

                <v-list-item class="mb-0">
                    <v-list-item-title>Tipo de atendimento</v-list-item-title>
                </v-list-item>
                <v-list-item class="mb-0">
                    <v-btn-toggle multiple mandatory v-model="pre_filter.types" dense color="primary">
                        <v-btn small v-for="(item, index) in types" :value="item" :key="index">
                            <v-icon size="18" class="mr-2">{{ item.icon }}</v-icon>
                            <span>{{ item.name }}</span>
                        </v-btn>
                    </v-btn-toggle>
                </v-list-item>

                <v-list-item class="mb-0">
                    <v-list-item-title>Departamento</v-list-item-title>
                </v-list-item>

                <v-list-item class="mb-0">
                    <v-btn-toggle borderless dense v-model="pre_filter.departments" background-color="transparent" multiple color="primary" class="d-flex flex-wrap">
                        <v-btn small bordered :value="item" v-for="(item, index) in user_role_departments" :key="index" class="mb-1" style="border: 1px solid grey">
                            <span>{{ item.name }}</span>
                        </v-btn>
                    </v-btn-toggle>
                </v-list-item>

                <v-list-item class="mb-0">
                    <v-list-item-title>Tags</v-list-item-title>
                </v-list-item>

                <v-list-item class="mb-0">
                    <v-btn-toggle borderless dense v-model="pre_filter.tags" multiple background-color="transparent" color="primary" class="d-flex flex-wrap">
                        <v-btn small bordered :value="item" v-for="(item, index) in tags" :key="index" class="mb-1" style="border: 1px solid grey">
                            <span>{{ item.name }}</span>
                        </v-btn>
                    </v-btn-toggle>
                </v-list-item>


                <v-list-item class="mb-0">
                    <v-list-item-title>Busca individual</v-list-item-title>
                </v-list-item>


                <v-list-item class="mb-2">
                    <v-text-field dense v-model="pre_filter.text" outlined label="Nome ou telefone" hide-details="auto"></v-text-field>
                </v-list-item>

                <v-list-item>
                    <div class="filter-item my-3" style="display: flex; width: 100%;">
                        <v-spacer></v-spacer>
                        <v-btn dark outlined color="error" class="mr-4" @click="clearFilters">Limpar filtros</v-btn>
                        <v-btn dark outlined color="primary" @click="applyFilters">Aplicar filtros</v-btn>
                    </div>
                </v-list-item>
            </v-list>
        </v-bottom-sheet>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="deleteMessageDialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar mensagem
                </v-card-title>
                <v-card-text> Tem certeza que deseja apagar essa mensagem? Essa ação é irreversível.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteMessageDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="deleteMessageDialog = false; deleteMessage()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="newConversationDialog" max-width="550">
            <v-card>
                <v-card-title class="text-h5">
                    Nova conversa
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select outlined v-model="new_conversation_chip" :items="allowed_chips" item-text="name" no-data-text="Não há números conectados" item-value="_id" label="Canal de comunicação" hide-details="auto">
                            </v-select>
                        </v-col>

                        <v-col cols="12">
                            <v-select outlined v-model="new_conversation_department" :items="user_role_departments" item-text="name" no-data-text="Não há departamentos" item-value="_id" label="Departamento" hide-details="auto">
                            </v-select>
                        </v-col>
                    </v-row>




                </v-card-text>

                <v-card-text>
                    <v-radio-group class="my-2" v-model="new_conversation_contact_type" row hide-details="auto">
                        <v-radio label="Contato existente" value="existing"></v-radio>
                        <v-radio label="Novo contato" value="new"></v-radio>
                    </v-radio-group>
                </v-card-text>

                <v-card-text v-if="new_conversation_contact_type == 'existing'">
                    <v-autocomplete v-model="new_contact" return-object outlined :items="contacts" :loading="loading" @update:search-input="searchContacts" chips clearable hide-details="auto" :item-text="getItemText" item-value="_id" label="Selecione um contato">
                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-title>
                                    Busque um contato pelo
                                    <strong>nome ou telefone</strong>
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ attr, on, item, selected }">
                            <v-chip v-bind="attr" :input-value="selected" color="primary" class="white--text" v-on="on">
                                <span v-text="item.override_name || item.name"></span>
                            </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-list-item-avatar class="text-h5 font-weight-light white--text">
                                <v-img v-if="item.contact?.profile_picture" :src="item.contact?.profile_picture"></v-img>
                                <div v-else class="w-full h-full primary d-flex justify-center">
                                    <v-icon color="white" style="font-size:55px">mdi-account</v-icon>
                                </div>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.override_name || item.name"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.phone"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-card-text>

                <v-card-text v-if="new_conversation_contact_type == 'new'">
                    <v-row>
                        <v-col cols="3">
                            <v-text-field v-model="country_code" outlined label="Cód País" hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="9">
                            <v-text-field v-model="new_conversation_phone" outlined label="Telefone com DDD" hide-details="auto" @change="new_conversation_jid = false"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="newConversationDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" text @click="checkPhone" v-if="new_conversation_contact_type == 'new' && !new_conversation_jid" :disabled="!new_conversation_chip">
                        Iniciar conversa
                    </v-btn>
                    <v-btn color="primary" text @click="startNewConversation" v-if="new_conversation_jid || (new_conversation_contact_type == 'existing' && new_contact)">
                        Iniciar conversa
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="tagsDialog" max-width="600">
            <v-card>
                <v-card-title class="text-h5">
                    Atribuir tags
                </v-card-title>
                <v-card-subtitle v-if="selectedConversation?.contact?.name">Organize o atendimento de {{ selectedConversation.contact.override_name || selectedConversation.contact.name }}</v-card-subtitle>
                <v-card-text v-if="selectedConversation?.contact">
                    <v-select chips small-chips multiple outlined v-model="selectedConversation.contact.tags" :items="tags" item-text="name" item-value="_id" label="Tags ou rótulos" hide-details="auto">
                        <template #selection="{ item }">
                            <v-chip small :color="item.color">{{ item.name }}</v-chip>
                        </template>
                    </v-select>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="tagsDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" text @click="tagsDialog = false; changeContactTags()">
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="flowsDialog" max-width="600">
            <v-card>
                <v-card-title class="text-h5">
                    Transferir para fluxo
                </v-card-title>
                <v-card-subtitle v-if="selectedConversation?.contact?.name">Organize o atendimento de {{
                    selectedConversation.contact.override_name || selectedConversation.contact.name }}</v-card-subtitle>
                <v-card-text v-if="selectedConversation?.contact">
                    <v-select chips small-chips outlined v-model="selectedConversation.flow" :items="flows" item-text="name" item-value="_id" label="Fluxo" hide-details="auto">
                        <template #selection="{ item }">
                            <v-chip small :color="item.color">{{ item.name }}</v-chip>
                        </template>
                    </v-select>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="flowsDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" text @click="flowsDialog = false; transferToFlow()">
                        Transferir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="departmentDialog" max-width="600">
            <v-card>
                <v-card-title class="text-h5">
                    Transferir departamento
                </v-card-title>
                <v-card-subtitle v-if="selectedConversation?.contact?.name">Transferir atendimento de {{
                    selectedConversation.contact.override_name || selectedConversation.contact.name }}</v-card-subtitle>
                <v-card-text>
                    <v-select chips small-chips outlined v-model="selectedConversation.department" :items="departments" item-text="name" item-value="_id" label="Departamento" hide-details="auto">
                        <template #selection="{ item }">
                            <v-chip small color="primary">{{ item.name }}</v-chip>
                        </template>
                    </v-select>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="departmentDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" text @click="departmentDialog = false; changeConversationData()">
                        Transferir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay z-index="99999" opacity="0.9" v-model="imageViewerDialog">
            <v-img :src="media_url" contain style="max-height: 85vh;max-width:85vw"></v-img>
            <div style="text-align: center;">
                <v-btn outlined class="mt-2 mr-4" large color="error lighten-1" @click="imageViewerDialog = false">
                    <v-icon class="mr-2">mdi-close</v-icon>
                    Fechar
                </v-btn>
                <v-btn outlined class="mt-2" large color="primary" @click="$saveImage(media_url)">
                    <v-icon class="mr-2">mdi-content-save-outline</v-icon>
                    Salvar
                </v-btn>
            </div>
        </v-overlay>

        <dialog-medias v-model="dialogMedia" :medias="medias" :updateMedias="updateMedias" :sendMedia="sendMedia"></dialog-medias>
        <dialog-scheduled-message v-model="dialogScheduledMessage" :phone="selectedConversation?.contact?.phone" :chips="chips"></dialog-scheduled-message>
        <dialog-recording v-model="dialogRecording" :sendMedia="sendMedia"></dialog-recording>
        <contact-drawer v-model="contactDrawer" :contact_id="selectedContact" :tags_dictionary="tags_dictionary"></contact-drawer>

        <dialog-finish-conversations v-if="['owner', 'manager'].includes(currentUser?.user_role)" v-model="dialogFinishConversations"></dialog-finish-conversations>
    </div>
</template>

<script>
let socket;
let socket_connection_count = 0;

import Api from "@/lib/Api";
import MessagePreview from "@/components/MessagePreview";
import DialogOrColumn from "@/components/DialogOrColumn";
import DialogMedias from "@/components/DialogMedias";
import DialogFinishConversations from "@/components/DialogFinishConversations.vue";
import DialogRecording from "@/components/DialogRecording";
import DialogScheduledMessage from "@/components/DialogScheduledMessage";
import ContactDrawer from "@/components/ContactDrawer";
import { emitToastr } from "@/lib/Utils";
import { VEmojiPicker } from 'v-emoji-picker';
let timeout = null;

export default {
    name: "AtendimentoView",

    components: {
        MessagePreview,
        DialogOrColumn,
        DialogMedias,
        DialogFinishConversations,
        DialogRecording,
        DialogScheduledMessage,
        ContactDrawer,
        VEmojiPicker
    },

    data() {
        return {
            selectedChatItem: {},
            filter_sheet: false,
            objects: [],
            tagsDialog: false,
            flowsDialog: false,
            departmentDialog: false,
            dialogMedia: false,
            dialogRecording: false,
            dialogScheduledMessage: false,
            newConversationDialog: false,
            deleteMessageDialog: false,
            dialogFinishConversations: false,
            contactDrawer: false,
            selectedContact: null,
            emojiDialog: false,
            imageViewerDialog: null,

            menu: false,
            filteredItems: [],

            media_url: '',
            selectedConversation: {},
            showConversation: false,
            pre_filter: {
                text: '', changed: false, tags: [], departments: [],
                types: [
                    { name: 'Chatbot', id: 'chatbot', icon: 'mdi-robot-outline' },
                    { name: 'Humano', id: 'human', icon: 'mdi-monitor-account' }
                ],
                situation: 'all'
            },
            can_clear: false,
            options: { filter: { text: '', finished: undefined }, limit: 15 },
            messages: [],
            rendered_messages: [],
            last_read_message_index: -1,
            quick_messages: [],
            autoselectMenu: false,
            button_load_more: true,
            text: "",
            loading: true,
            typing: false,
            types: [
                { name: 'Chatbot', id: 'chatbot', icon: 'mdi-robot-outline' },
                { name: 'Humano', id: 'human', icon: 'mdi-monitor-account' }
            ],
            conversation_situations: [
                { name: 'Todas', id: 'all', icon: 'mdi-forum-outline' },
                { name: 'Não respondidas', id: 'waiting_response', icon: 'mdi-message-text-clock-outline' }
            ],
            chips: [],
            allowed_chips: [],
            medias: [],
            users: {},
            departments: [],
            user_role_departments: [],
            flows: [],
            contacts: [],
            tags: [],
            tags_dictionary: {},
            new_conversation_phone: "",
            new_conversation_contact_type: "existing",
            country_code: "55",
            new_conversation_jid: false,
            new_conversation_chip: false,
            new_conversation_department: false,
            new_contact: null
        };
    },
    watch: {
        text(value) {
            if (value.endsWith('/')) {
                this.filteredItems = this.quick_messages;
                this.menu = true;
            } else if (!value.includes('/')) {
                this.menu = false;
            }
        }
    },

    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        },

        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },

        showMessageDialog() {
            if (this.conversation?._id) return true;
            return false;
        },

        getSelectedTypes() {
            if (this.pre_filter?.types && this.pre_filter.types.length > 0) {
                let selections = '';
                this.pre_filter.types.forEach(i => selections = selections + `, ${i.name}`)
                return selections.substring(2);
            }
            else {
                return 'Todos os tipos'
            }
        },

        getSelectedDepartments() {
            if (this.pre_filter?.departments && this.pre_filter.departments.length > 0) {
                let selections = '';
                this.pre_filter.departments.forEach(i => selections = selections + `, ${i.name}`)
                return selections.substring(2);
            }
            else {
                return 'Todos os departamentos'
            }
        },

        getSelectedTags() {
            if (this.pre_filter?.tags && this.pre_filter.tags.length > 0) {
                let selections = '';
                this.pre_filter.tags.forEach(i => selections = selections + `, ${i.name}`)
                return selections.substring(2);
            }
            else {
                return 'Todos os contatos'
            }
        }
    },

    async mounted() {
        this.loading = true;

        if (!socket) this.startSocket();
        const tags_call = Api.getTagsByUser();
        const departments_call = Api.getDepartmentsByUser();
        const quick_messages_call = Api.getQuickMessagesByUser();
        const chips_call = Api.getChipNumbersByUser();
        const medias_call = Api.getMediasByUser();
        const users_call = Api.getTeamUsers(0);
        const flows_call = Api.getFlowsByUser();

        const promisess = [await tags_call, await departments_call, await quick_messages_call, await chips_call, await medias_call, await users_call, await flows_call];

        const tags = promisess[0];
        const departments = promisess[1];
        const quick_messages = promisess[2];
        const chips = promisess[3];
        const medias = promisess[4];
        const users = promisess[5];
        const flows = promisess[6];

        if (!tags.error && tags.message) this.tags = tags.message;
        this.tags.forEach(i => this.tags_dictionary[i._id] = { name: i.name, color: i.color });


        if (!departments.error && departments.message) {
            this.departments = departments.message;

            if (this.currentUser.user_role == 'user') {
                this.user_role_departments = this.departments.filter(d => (this.currentUser.departments || []).includes(d._id.toString()));
            }
            else {
                this.user_role_departments = this.departments;
            }
        }

        if (!quick_messages.error && quick_messages.message) {
            this.quick_messages = [];
            quick_messages.message.forEach(m => this.quick_messages.push({ shortcut: `/${m.shortcut}`, message: m.message }));
        }

        if (!chips.error && chips.message) {
            this.chips = chips.message.filter(i => i.status == 'open');

            if (this.currentUser.user_role === 'user') {
                this.allowed_chips = this.chips.filter(c => (this.currentUser.departments || []).includes(c.department));
            }
            else {
                this.allowed_chips = this.chips;
            }

            if (this.allowed_chips.length) this.new_conversation_chip = this.allowed_chips[0];
        }

        if (!medias.error && medias.message) {
            this.medias = medias.message;
        }

        if (!users.error && users.message) {
            this.users = {};
            users.message.forEach(u => { this.users[u._id.toString()] = u.name; })
        }

        if (!flows.error && flows.message) {
            this.flows = flows.message;
        }

        this.buildFilter();
        //NOTE: OK
        await this.getConversations(this.options);

        if (this.isMobile) {
            try {
                document.removeEventListener("visibilitychange", () => { });

                document.addEventListener("visibilitychange", async () => {
                    if (!document.hidden) {
                        //NOTE: OK
                        this.getConversations(this.options);
                    }
                });
            }
            catch (err) {
                console.log(err.message)
            }
        }
    },

    async beforeDestroy() {
        socket.disconnect();
        socket = null;
    },

    methods: {
        toogleDialogEmoji() {
            this.emojiDialog = !this.emojiDialog;
        },
        onSelectEmoji(emoji) {
            this.text += emoji.data;
            // Optional
            // this.toogleDialogEmoji();
        },
        handleKeyDown(event) {
            event.preventDefault();

            if (event.key === 'Enter') {
                if (event.shiftKey) {
                    this.text += '\n';
                } else {
                    this.sendMessage();
                }
            }
        },

        handleInput() {
            if (this.text && this.text[0] == '/') {
                this.filteredItems = this.quick_messages.filter((item) =>
                    item.shortcut.toLowerCase().includes(this.text.split('/').pop().toLowerCase())
                );
                this.menu = true;
            } else {
                this.menu = false;
            }
        },

        buildFilter() {
            this.options.filter.types = this.pre_filter.types;
            this.options.filter.tags = this.pre_filter.tags;
            this.options.filter.text = this.pre_filter.text;
            this.options.filter.situation = this.pre_filter.situation?.id;
            this.options.limit = 15;

            //TRATANDO DEPARTAMENTOS
            if (this.currentUser.user_role == 'user') {
                if (!this.currentUser.departments) this.currentUser.departments = [];

                if (this.pre_filter.departments.length == 0) {
                    this.pre_filter.departments = this.user_role_departments;
                }
            }

            this.options.filter.departments = this.pre_filter.departments;
        },

        sortConversations() {
            this.objects = this.objects.sort((a, b) => {
                const dateA = a.last_message && a.last_message.created ? new Date(a.last_message.created).getTime() : 0;
                const dateB = b.last_message && b.last_message.created ? new Date(b.last_message.created).getTime() : 0;
                return dateB - dateA;
            });
        },

        async sendMessage(media = {}) {
            if (!media?._id && !media?.url && this.text == '') return;

            this.$nextTick(async () => {
                const m_id = this.$newID('.OP');
                this.selectedConversation.waiting_response = false;

                this.pushMessage({ created: new Date(), event: 'new_message', conversation_id: this.selectedConversation._id, message: this.text, message_id: m_id, platform_message_id: m_id, origin: 'user', type: media.type || 'text', media_url: media?.url, user: this.currentUser._id });

                let send_message_call = Api.sendConversationMessage(this.selectedConversation._id, this.text, m_id, media);
                this.selectedConversation.waiting_response = false;
                this.selectedConversation.is_new = false;
                this.text = "";

                send_message_call = await send_message_call;

                //APAGANDO MENSAGEM COM ERRO
                if (send_message_call.error) {
                    const error_message_index = this.messages.findIndex(m => m.message_id === m_id);
                    this.messages.splice(error_message_index, 1);
                }
            });
        },

        startSocket() {
            if (socket_connection_count >= 3) {
                emitToastr("error", "Erro na conexão com o socket. Atualize a página para tentar novamente.");
                return;
            }

            if (!this.$store.state.SOCKET_IO_URL) {
                socket_connection_count++
                setTimeout(() => { this.startSocket(); }, 500 + socket_connection_count * 2000);
                emitToastr("info", "Aguardando endereço do socket...");
                return;
            }

            socket_connection_count = 0;
            socket = this.$io(this.$store.state.SOCKET_IO_URL, { path: '/sock/' });

            socket.on("connect", async () => {
                socket.emit("join-room", `account_conversations_${this.currentUser?.account?._id}`);
            });

            socket.on("receive-message", data => {
                if (data.event === "new_message") {
                    this.pushMessage(data);
                }

                if (data.event === "new_conversation") {
                    setTimeout(() => {
                        this.getConversations({ ...this.options, conversation_id: data.conversation._id }, true);
                    }, 2000);
                }

                if (data.event === "conversation_updated") {
                    setTimeout(() => {
                        this.getConversations({ ...this.options, conversation_id: data.conversation._id }, true);
                    }, 1000);
                }

                if (data.event === "conversation_finished") {
                    if (this.selectedConversation?._id?.toString() == data.conversation_id) {
                        if (data.user_id && this.currentUser._id != data.user_id) emitToastr("info", "Essa conversa foi finalizada por outro usuário");
                        this.selectedConversation = {};
                        this.messages = [];
                        this.rendered_messages = [];
                    }

                    const c_index = this.objects.findIndex(i => i._id.toString() == data.conversation_id);
                    if (c_index != -1) {
                        this.objects.splice(c_index, 1);
                        this.$forceUpdate();
                    }
                }

                if (data.event === "message_read") {
                    if (this.showConversation && this.selectedConversation?._id?.toString() == data.conversation_id) this.setMessagesRead(data.platform_message_id);
                }

                if (data.event === "set_manual") {
                    this.getConversations({ ...this.options, conversation_id: data.conversation_id }, true);

                    if (this.selectedConversation?._id?.toString() == data.conversation_id.toString()) {
                        this.selectedConversation.is_manual = true;
                    }
                }
            });
        },

        findConversationIndex(conversation_id) {
            return this.objects.findIndex(c => c._id.toString() === conversation_id);
        },

        async getConversations(options, notify = false) {
            this.loading = true;
            let resp = await Api.getConversationsWithLastMessage(options);
            this.loading = false;

            const last_conversation_list_length = this.objects.length;

            if (!resp.error) {
                //ÚNICA CONVERSA
                if (options.conversation_id) {
                    const conversation_index = this.findConversationIndex(options.conversation_id);

                    if (conversation_index != -1) {
                        //EXISTE NA LISTA
                        if (resp.message) {
                            //O USUÁRIO PODE VER A CONVERSA - SUBSTITUIR
                            this.objects[conversation_index] = resp.message;
                        }
                        else {
                            //NÃO PODE VER A CONVERSA - APAGAR
                            this.objects.splice(conversation_index, 1);
                        }
                    }
                    else {
                        //NÃO EXISTE NA LISTA
                        if (resp.message) {
                            this.objects.push(resp.message);
                        }
                    }
                }
                else {
                    //LISTA DE CONVERSAS
                    this.objects = resp.message;
                    this.button_load_more = resp.load_more;
                }

                if (this.pre_filter.tags.length > 0) this.objects = this.objects.filter(i => this.pre_filter.tags.some(j => i.contact?.tags.includes(j._id)));
                this.totalObjects = resp.message.length;

                if (this.selectedConversation?._id) {
                    this.selectedConversation = this.objects.find(x => x._id == this.selectedConversation._id);
                }

                if (!this.selectedConversation) {
                    this.selectedConversation = {};
                    this.messages = [];
                    this.rendered_messages = [];
                }

                this.sortConversations();

                //NOTIFICAR CASO TENHA UMA MENSAGEM NOVA
                if (notify && last_conversation_list_length < this.totalObjects) this.$notify("InteligênciaBot", "Nova conversa");
            }
        },

        async pushMessage(data) {
            const conversation_index = this.findConversationIndex(data.conversation_id);

            if (conversation_index != -1) {
                //CONVERSA EXISTE NA LISTA
                this.objects[conversation_index].last_message = data;

                if (data.origin === 'customer') {
                    this.objects[conversation_index].waiting_response = true;
                }
                else {
                    this.objects[conversation_index].waiting_response = false;
                    //if (this.options.filter.situation == "waiting_response") this.objects.splice(conversation_index, 1);
                }

                this.sortConversations();
                this.$forceUpdate()
            }
            else {
                //CONVERSA NÃO ESTÁ LISTADA
                setTimeout(() => {
                    this.getConversations({ ...this.options, conversation_id: data.conversation_id }, true);
                }, 1000);
            }


            if (data?.conversation_id.toString() != this.selectedConversation?._id?.toString()) {
                if (this.selectedConversation?._id?.toString() != data.conversation_id.toString() && data.origin == 'customer') this.$playNotificationSound();
                return;
            }

            let delay = 1;

            if (data.origin == 'customer') {
                this.typing = true;
                delay = 1000;
            }

            let message = this.messages.find(m => m.message_id == data.message_id);
            if (message && !message.platform_message_id && data.platform_message_id) message.platform_message_id = data.platform_message_id;

            setTimeout(() => {
                if (!data.message_id || !message) {
                    this.messages.push(data);
                    this.rendered_messages.push(data);
                    this.last_read_message_index = this.messages.findLastIndex(m => m.status >= 4);
                    this.typing = false;

                    this.$nextTick(() => {
                        this.scrollMessagesToBottom('smooth');
                    });
                }
            }, delay);
        },

        scrollMessagesToBottom(behavior = 'smooth') {
            const dialogElement = this.$refs.dialogRef;

            if (dialogElement) {
                setTimeout(() => {
                    dialogElement.scrollTo({ top: dialogElement.scrollHeight + 1000, behavior });
                }, 30);
            }
        },

        setMessagesRead(message_id) {
            const message_index = this.messages.findIndex(m => m.platform_message_id == message_id);

            if (this.messages[message_index]) {
                this.messages[message_index].status = 4;
                if (message_index > this.last_read_message_index) this.last_read_message_index = message_index;
                this.$forceUpdate();
            }
        },

        loadMoreMessages() {
            this.rendered_messages = this.messages.slice(-1 * (this.rendered_messages.length + 25));

            this.$nextTick(() => {
                const element = document.getElementById(`message_25`);
                if (element) element.scrollIntoView({ behavior: "instant", block: "start" });
            })
        },

        async getMessages(conversation) {
            // eslint-disable-next-line
            if (tianji) tianji.track('open_conversation', {});

            if (this.selectedConversation && this.selectedConversation._id != conversation._id) {
                this.text = "";
                this.messages = [];
                this.rendered_messages = [];
            }

            if (this.selectedConversation && this.selectedConversation._id == conversation._id) return;

            this.typing = false;
            this.selectedConversation = conversation;
            this.showConversation = true;
            const resp = await Api.getMessagesByConversation(conversation._id);

            if (!resp.error && resp.message) {
                this.messages = resp.message;
                this.rendered_messages = this.messages.slice(-25);
                this.last_read_message_index = this.messages.findLastIndex(m => m.status >= 4);

                setTimeout(() => {
                    this.scrollMessagesToBottom('instant');
                }, 50);
            }
        },

        async finishConversation(conversation) {
            const resp = await Api.finishConversation(conversation._id);

            if (!resp.error && resp.message) {
                const conversation_index = this.findConversationIndex(conversation._id.toString());
                if (conversation_index != -1) this.objects.splice(conversation_index, 1);

                this.selectedConversation = {};
                this.showConversation = false;
                this.messages = [];
                this.rendered_messages = [];
            }
        },

        async setManual(conversation) {
            const resp = await Api.setConversationManual(conversation._id);
            if (!resp.error && resp.message) {
                conversation.is_manual = true;
            }
        },

        async changeConversationData() {
            const resp = await Api.changeConversationData(this.selectedConversation);
            if (!resp.error) {
                emitToastr("success", "Conversa atualizada");
                this.selectedConversation = {};
                this.messages = [];
                this.rendered_messages = [];
                this.showConversation = false;
            }
        },

        async transferToFlow() {
            if (!this.selectedConversation.flow) return emitToastr("error", "É necessário selecionar um fluxo antes de transferir");
            this.selectedConversation.chatbot_type = 'flow_chatbot';
            this.selectedConversation.is_manual = false;
            this.selectedConversation.start_flow = true;
            this.selectedConversation.last_flow_context = "";
            delete this.selectedConversation.chatbot;

            const resp = await Api.changeConversationData(this.selectedConversation);
            if (!resp.error) {
                emitToastr("success", "Conversa atualizada");
            }
        },

        async changeContactTags() {
            const resp = await Api.changeContactTags(this.selectedConversation.contact);
            if (!resp.error) emitToastr("success", "Tags atualizadas");
        },

        clearFilters() {
            this.pre_filter = {
                text: '', changed: false, tags: [], departments: [],
                types: [
                    { name: 'Chatbot', id: 'chatbot', icon: 'mdi-robot-outline' },
                    { name: 'Humano', id: 'human', icon: 'mdi-monitor-account' }
                ]
            };
            this.can_clear = false;
            this.buildFilter();
            //NOTE: OK
            this.getConversations(this.options);
            this.filter_sheet = false;
        },

        applyFilters() {
            this.selectedConversation = {};
            this.messages = [];
            this.rendered_messages = [];
            this.can_clear = true;
            this.buildFilter();
            //NOTE: OK
            this.getConversations(this.options);
            this.filter_sheet = false;
        },

        openFile(url) {
            window.open(url, "_blank");
        },

        async checkPhone() {
            this.new_conversation_jid = false;
            this.loading = true;
            const resp = await Api.checkPhone(this.country_code + this.new_conversation_phone);
            this.loading = false;
            if (!resp.error && resp.message) {
                this.new_conversation_jid = resp.message;
                this.startNewConversation();
            }

        },

        async startNewConversationDialog() {
            this.new_contact = null;
            this.contacts = [];
            this.new_conversation_contact_type = 'existing';
            this.new_conversation_phone = '';
            this.new_conversation_jid = false;
            this.newConversationDialog = true
        },

        async startNewConversation() {
            this.newConversationDialog = false;

            if (this.new_conversation_contact_type == 'new') {
                await Api.startNewConversation(this.new_conversation_jid, this.new_conversation_chip, this.new_conversation_department);
            }
            else {
                await Api.startNewConversation(this.new_contact.channel_contact_id, this.new_conversation_chip, this.new_conversation_department);
            }
        },

        openMedias() {
            this.dialogMedia = true;
        },

        openRecording() {
            this.dialogRecording = true;
        },

        updateMedias(message) {
            if (message.type == 'delete') {
                const i = this.medias.findIndex(m => m._id.toString() == message.media_id);
                if (i != -1) this.medias.splice(i, 1);
            }
            else {
                this.medias.push(message);
            }
        },

        async sendMedia(media) {
            this.dialogMedia = false;
            this.sendMessage(media);
        },

        async deleteMessage() {
            const resp = await Api.deleteConversationMessage(this.selectedChatItem.platform_message_id, this.selectedConversation.contact?.phone, this.selectedConversation.chip);
            if (!resp.error && resp.message == 'ok') {
                this.selectedChatItem.deleted = true;
                this.$forceUpdate();
            }
        },

        showImage(media_url) {
            this.media_url = media_url;
            this.imageViewerDialog = true;
        },

        openContact(contact_id) {
            this.selectedContact = contact_id;
            this.contactDrawer = true;
        },

        getItemText(item) {
            return item.override_name || item.name;
        },

        async searchContacts(val) {
            this.loading = true;
            if (timeout) clearTimeout(timeout);

            timeout = setTimeout(async () => {
                this.options.page = 1;
                this.loading = true;
                const resp = await Api.getContactsByUser({ itemsPerPage: 10, query: val });
                if (!resp.error && resp.message) {
                    this.contacts = resp.message.data;
                    this.loading = false;
                }
                this.loading = false;
            }, 250);
        }
    },
};
</script>

<style scoped>
.custom-page {
    height: auto;
    max-height: 100%;
    overflow: hidden;
    padding: 0 !important;
}

.conversation-list {
    height: calc(100vh - 214px);
    overflow-y: scroll;
    scrollbar-width: thin !important;
    scrollbar-color: rgba(74, 187, 52, 0.322) transparent !important;
}

.conversation-container {
    position: relative;
    height: calc(100vh - 204px);
    overflow-y: scroll;
    scrollbar-width: thin !important;
    scrollbar-color: rgba(74, 187, 52, 0.322) transparent !important;
}

.contact-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.contact-info {
    display: flex;
    flex-direction: column;
    margin-left: 14px;
}

.contact-name {
    font-size: 18px;
    font-weight: 600;
}

.contact-phone {
    font-size: 14px;
}

.messages-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: calc(100vh - 255px);
    font-size: 14px;
}

.chat-item {
    max-width: 400px;
    padding: 8px;
    padding-bottom: 4px;
    margin-top: 6px;
    margin-bottom: 6px;
    border-radius: 6px;
    white-space: pre-line;
    word-wrap: break-word;
}

.chatbot-message {
    background-color: #e2ffc9;
    align-self: flex-end;
}

.customer-message {
    background-color: #ffffff;
    align-self: flex-start;
}

.deleted-message {
    background-color: #ffdfdf !important;
}

.message-time {
    text-align: right;
    font-size: 11px;
    color: #b1b1b1;
}

.command-bar {
    height: 80px;
    position: fixed;
    display: flex;
    bottom: 0;
    background-color: rgb(245, 245, 245);
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
}

.filter-bar {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.filter-item {
    font-size: 13px;
}

.one-line {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.video-container {
    width: 100%;
    max-height: 50vh;
    border-radius: 6px;
}

.reply-container {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    font-style: italic;
    font-size: 12px;
}

::v-deep .v-dialog {
    scrollbar-width: thin !important;
    scrollbar-color: rgba(74, 187, 52, 0.596) transparent !important;
}

#exampleInputEmoji {
    position: absolute;
    transform: translateY(-200px);
    z-index: 999;
}

@media only screen and (max-width: 960px) {
    .conversation-container {
        height: calc(100vh - 120px);
    }

    .contact-info {
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .contact-name {
        font-size: 15px !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .messages-container {
        height: calc(100vh - 190px);
    }
}
</style>