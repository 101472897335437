import { ClassicPreset } from "rete";
import BaseNode from "@/flow-editor/base/BaseNode";

export default class StartNode extends BaseNode {
    static type = "start";
    static category = "action";
    static icon = "mdi-play";
    
    constructor() {
        super("Início");

        const socket = new ClassicPreset.Socket("socket");

        this.addOutput("output", new ClassicPreset.Output(socket, "", false));
    }
}