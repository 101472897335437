<template>
    <div class="menu-page">
        <v-alert prominent class="success lighten-1" dark icon="mdi-school-outline">
            <v-row align="center">
                <v-col class="grow">
                    Quer aprender a criar um chatbot de IA profissional? Assista nossos vídeos para ficar expert no assunto.
                </v-col>
                <v-col class="shrink" @click="$router.push('/ajuda')">
                    <v-btn>Ver vídeos</v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <v-card>
            <v-card-title>
                Chatbots de IA
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">


                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" v-bind="attrs" v-on="on">Novo chatbot inteligente</v-btn>
                            </template>

                            <v-list dense>

                                <v-list-item two-line class="cursor-pointer" @click="openNew">
                                    <v-list-item-content class="pt-0 pb-0">
                                        <v-list-item-title><b>CRIAR DO ZERO</b></v-list-item-title>
                                        <v-list-item-subtitle class="cursor-pointer">Sem a utilização de modelo ou assistente de criação de prompt</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>


                                <v-list-item two-line v-for="(item, index) in chatbot_models" :key="`model_${index}`" class="cursor-pointer" @click="newChatbotByModel(item)">
                                    <v-list-item-content class="pt-0 pb-0">
                                        <v-list-item-title><b>{{ item.name }}</b></v-list-item-title>
                                        <v-list-item-subtitle class="cursor-pointer">{{ item.description }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="objects" :loading="loading">

                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon medium class="mr-2" color="error lighten-1" @click="object = item; dialogDelete = true" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>Apagar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon medium color="primary" @click="object = item; dialogCreate = true" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog persistent scrollable v-model="dialogCreate">
            <v-card class="mb-4">
                <v-card-title class="primary">
                    Criação de chatbot inteligente
                </v-card-title>

                <v-card-subtitle class="mb-4 primary">
                    Crie aqui um assistente completo, com as características mais adequadas para atender o seu público
                </v-card-subtitle>

                <v-card-text>
                    <v-card-text class="mt-0">
                        <v-row>
                            <v-col cols="12" lg="6" md="6" sm="12">
                                <v-text-field v-model="object.name" label="Identificador do chatbot" placeholder="Um nome para diferenciar esse chatbot de outros, se existirem" outlined hide-details="auto" />
                            </v-col>
                        </v-row>
                    </v-card-text>


                    <v-divider></v-divider>

                    <v-card-title style="color: black">
                        Persona
                    </v-card-title>

                    <v-card-subtitle class="mb-4">
                        Aqui vamos definir quem é o seu chatbot
                    </v-card-subtitle>

                    <v-card-text class="mt-0">
                        <v-row>
                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-text-field v-model="object.instructionsObject.persona_name" label="Nome da persona" placeholder="Como o chatbot se chama?" outlined hide-details="auto" />
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-select chips small-chips clearable v-model="object.instructionsObject.persona_gender" item-text="name" item-value="id" :items="genders" label="Gênero" outlined hide-details="auto">
                                    <template #selection="{ item }">
                                        <v-chip small color="primary">{{ item.name }}</v-chip>
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col cols="12" lg="4" md="4" sm="12">
                                <v-select clearable chips small-chips multiple v-model="object.instructionsObject.personality_types" :items="personalities" label="Personalidade(s) (escolha até 3)" outlined hide-details="auto" @change="checkPersonalities">
                                    <template #selection="{ item }">
                                        <v-chip small color="primary">{{ item }}</v-chip>
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field v-model="object.instructionsObject.persona_mission" label="Missão do chatbot" placeholder="Escreva aqui, de forma completa, qual a missão deste chatbot" outlined hide-details="auto" />

                                <v-expansion-panels focusable class="mt-2">
                                    <v-expansion-panel class="primary lighten-2">
                                        <v-expansion-panel-header>Exemplos de missão</v-expansion-panel-header>
                                        <v-expansion-panel-content class="pt-2">
                                            <div>Fornecer suporte técnico, auxiliando os clientes na configuração e uso adequado do produto.</div>
                                            <div>Personalizar a experiência do cliente, oferecendo sugestões de produtos com base em suas preferências e histórico de compras.</div>
                                            <div>Auxiliar na resolução de problemas comuns, como dificuldades de acesso à conta, esquecimento de senha e problemas de navegação no site.</div>
                                            <div>Fornecer informações detalhadas sobre o currículo do curso, incluindo disciplinas, carga horária e objetivos de aprendizagem.</div>
                                            <div>Orientar os clientes sobre processos de devolução, troca e política de reembolso da empresa.</div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>


                    <v-divider></v-divider>

                    <v-card-title style="color: black">
                        Contexto e conhecimento do chatbot
                    </v-card-title>

                    <v-card-subtitle class="mb-4">
                        O seu assistente saberá responder sobre tudo que está aqui, não economize nas informações. Tudo que você quer que ele saiba responder precisa estar aqui
                    </v-card-subtitle>

                    <v-card-text class="mt-0">
                        <v-row>

                            <v-col cols="12">
                                <v-textarea v-model="object.instructionsObject.context" rows="10" label="Contexto geral" placeholder="Aqui você escreve (ou cola) toda a base de conhecimento do chatbot. Ele saberá responder apenas sobre o conteúdo aqui inserido. Vá fundo, coloque toda informação possível aqui." outlined hide-details="auto" />
                            </v-col>



                            <v-col cols="12">
                                <v-text-field v-model="object.instructionsObject.fallback" label="Como agir se não tiver a resposta?" placeholder="Oriente ensinando ao chatbot o que fazer (exemplo: Diga que ainda não tem essa informação. Ofereça suporte através do e-mail suporte@suaempresa.com)" outlined hide-details="auto" />

                                <v-expansion-panels focusable class="mt-2">
                                    <v-expansion-panel class="primary lighten-2">
                                        <v-expansion-panel-header>Exemplos de textos</v-expansion-panel-header>
                                        <v-expansion-panel-content class="pt-2">
                                            <div>Diga que não sabe falar sobre isso, mas você pode entrar em contato com nosso suporte em seusuporte@seusite.com</div>
                                            <div>Fale que ainda não tem essa informação. Ofereça ajuda com outra questão.</div>
                                            <div>Se desculpe por não saber falar sobre. Ofereça uma nova ajuda com outro assunto.</div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-title style="color: black">
                        Ajustes gerais
                    </v-card-title>

                    <v-card-subtitle class="mb-2">
                        Configure suas preferências de atendimento
                    </v-card-subtitle>

                    <v-card-text class="mt-0">
                        <v-row>
                            <v-col cols="12" lg="6" md="6" sm="12">
                                <v-text-field v-model="object.response_delay" label="Atraso na resposta (em segundos)" hide-details="auto" type="number" messages="Se quiser que o chatbot responda imediatamente, digite 0. Se quiser que demore alguns segundos, digite um tempo adequado." outlined />
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12">
                                <v-switch inset v-model="object.chatbot_auto_transfer_if_is_media" label="Transferir a conversa ao receber imagens ou documentos"></v-switch>
                            </v-col>

                            <v-col cols="12">
                                <v-switch inset v-model="object.chatbot_auto_transfer" label="Transferir a conversa quando o contato solicitar atendimento humano"></v-switch>
                            </v-col>

                            <v-col cols="12" v-if="object.chatbot_auto_transfer || object.chatbot_auto_transfer_if_is_media">
                                <v-select chips mandatory small-chips v-model="object.redirect" item-text="name" item-value="id" :items="redirects" label="Transferir para?" color="primary" outlined hide-details="auto" @change="changeChatbot(item)"></v-select>
                            </v-col>

                            <v-col cols="12" v-if="object.redirect == 'human'">
                                <v-text-field v-if="object.chatbot_auto_transfer" v-model="object.chatbot_auto_transfer_message" label="Aviso de transferência para atendimento humano" placeholder="Quando o chatbot transferir o atendimento para humano, o que ele dirá para o usuário?" hide-details="auto" outlined messages="Escreva literalmente o que o chatbot irá dizer no momento da transferência para o atendimento humano." />
                            </v-col>

                            <v-col cols="12" v-if="object.redirect == 'flow_chatbot' && (object.chatbot_auto_transfer || object.chatbot_auto_transfer_if_is_media)">
                                <v-select chips mandatory small-chips v-model="object.flow" item-text="name" item-value="_id" :items="flows" label="Fluxo" color="primary" outlined hide-details="auto" @change="changeChatbot(item)"></v-select>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field v-model="object.finish_conversation_message" label="Frase secreta para encerrar atendimento humano" placeholder="Digite aqui uma frase e lembre-se dela quando quiser encerrar um atendimento pelo seu celular" outlined messages="Sempre que uma pessoa falar no canal, o chatbot iniciará o atendimento. Caso você responda diretamente pelo Whatsapp do seu celular, o chatbot não atuará mais, considerando então este atendimento como atendimento humano. Para encerrar o atendimento humano via plataforma, basta clicar no botão de mesmo nome. Porém, caso você esteja no seu celular, poderá cadastrar uma frase aqui que, ao ser enviada por você, encerrará automaticamente o atendimento. Tente algo como 'Se precisar de mim é só chamar'. Você precisará falar exatamente a mesma frase" />
                            </v-col>
                        </v-row>
                    </v-card-text>


                    <v-card-title style="color: black">
                        Temperatura
                    </v-card-title>
                    <v-card-subtitle class="mb-4">
                        Quanto maior, mais criativo o chatbot fica, mas ele pode inventar respostas. Quanto menor, mais preciso e fiel ao conteúdo. RECOMENDAÇÃO: 0.5 na maior parte dos casos. Próximo de 0 para orçamentos ou cálculos. Próximo de 0.8 para conteúdos com muito texto e focados em
                        comunicação,
                        ajuda com dúvidas.
                    </v-card-subtitle>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="mt-3">
                                <v-slider v-model="object.temperature" thumb-label="always" max="1" min="0" step="0.1"></v-slider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card-text>


                <v-card-actions class="d-flex flex-wrap pb-4">
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" color="primary" outlined @click.native="getObjects(); dialogCreate = false">Cancelar</v-btn>
                    <v-btn class="my-1" color="primary" @click.native="createNew" :loading="loading">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="dialogDelete" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar chatbot
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar o chatbot <b>{{ object.name }}</b>?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogDelete = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="dialogDelete = false; deleteChatbot(object)" :loading="loading">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent scrollable v-model="dialogNewModel" max-width="900">
            <v-card class="mb-4">
                <v-card-title class="primary">
                    {{ selectedModel?.name }}
                </v-card-title>

                <v-card-subtitle class="mb-4 primary">
                    Preencha os campos com informações do seu negócio para que nosso sistema crie seu chatbot
                </v-card-subtitle>

                <v-card-text>
                    <v-row class="pt-2">
                        <v-col cols="12" v-for="(item, index) in selectedModel?.content" :key="`col_${index}`">
                            <v-text-field v-model="newChatbotModel[item.name]" v-if="item.type === 'v-text-field'" outlined :required="item.required" hide-details="auto" :label="item.description"></v-text-field>
                            <v-textarea v-model="newChatbotModel[item.name]" v-if="item.type === 'v-textarea'" rows="3" :required="item.required" outlined hide-details="auto" :label="item.description"></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text>
                    {{ newChatbotText }}
                </v-card-text>

                <v-card-actions class="d-flex flex-wrap pb-4">
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" color="primary" outlined @click.native="dialogNewModel = false">Cancelar</v-btn>
                    <v-btn class="my-1" color="primary" @click.native="createChatbotText" :loading="loading">Pré configurar chatbot</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Api from '@/lib/Api';
import { emitToastr } from '@/lib/Utils';

//import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";

export default {
    name: "ChatbotsView",

    data() {
        return {
            dialogCreate: false,
            dialogDelete: false,
            dialogNewModel: false,
            objects: [],
            flows: [],
            chatbot_models: [],
            selectedModel: { name: '', variables: [] },
            newChatbotModel: {},
            newChatbotText: '',
            headers: [
                { text: 'Identificador', value: 'name', sortable: false },
                { text: 'Nome da persona', value: 'instructionsObject.persona_name', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            redirects: [{ name: 'Chatbot de Fluxo', id: 'flow_chatbot' }, { name: 'Atendimento humano', id: 'human' }],
            object: {
                name: "",
                temperature: 0.3,
                instructionsObject: {
                    persona_name: "",
                    persona_gender: "feminina",
                    persona_mission: "",
                    personality_types: [],
                    fallback: "",
                    context: ""
                }
            },
            genders: [
                { id: "feminina", name: "Feminino" },
                { id: "masculina", name: "Masculino" }
            ],
            personalities: [
                "Amigável",
                "Profissional",
                "Engraçado",
                "Prestativo",
                "Encorajador",
                "Empático",
                "Direto ao ponto",
                "Inspirador",
                "Educado",
                "Animado",
                "Simpático",
                "Sofisticado",
                "Sarcástico",
                "Encantador",
                "Tranquilo",
                "Divertido",
                "Descontraído",
                "Formal",
                "Informal",
                "Perspicaz",
                "Vendedor"
            ],
            loading: false
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    async mounted() {
        this.getObjects();
    },

    methods: {
        async getObjects() {
            this.loading = true;
            const resp = await Api.getChatbotsByUser();

            if (resp.error) {
                emitToastr("error", resp.message || "Erro ao carregar chatbots")
            }
            else {
                if (resp.message) this.objects = resp.message;
            }

            const flows = await Api.getFlowsByUser();

            if (flows.error) {
                emitToastr("error", flows.message || "Erro ao carregar fluxos")
            }
            else {
                if (flows.message) this.flows = flows.message;
            }

            const models = await Api.getChatbotModels();

            if (!models.error && models.message) this.chatbot_models = models.message;

            this.loading = false;

        },

        clear() {
            this.object = {
                name: "",
                temperature: 0.3,
                instructionsObject: {
                    persona_name: "",
                    persona_gender: "feminina",
                    persona_mission: "",
                    personality_types: [],
                    fallback: "",
                    context: ""
                }
            };
        },

        checkPersonalities(p) {
            if (p.length > 3) {
                p.length = 3;
                emitToastr("warning", "Máximo de características de personalidade atingido.");
            }
        },

        openNew() {
            this.clear();
            this.dialogCreate = true;
        },

        async createNew() {
            if (this.object.name == "") return emitToastr("error", "Identificador do chatbot necessário");
            if (this.object.instructionsObject == "") return emitToastr("error", "Instruções necessárias");
            if (this.object.instructionsObject.persona_name == "") return emitToastr("error", "Nome da persona necessário");
            if (this.object.instructionsObject.persona_gender == "") return emitToastr("error", "Gênero da persona necessário");
            if (this.object.instructionsObject.persona_mission == "") return emitToastr("error", "Missão da persona necessária");
            if (this.object.instructionsObject.personality_types.length === 0) return emitToastr("error", "Selecione pelo menos 1 tipo de personalidade");
            if (this.object.instructionsObject.fallback == "") return emitToastr("error", "Digite como o chatbot deve responder caso façam uma pergunta que ele não sabe ou não pode responder");
            if (this.object.instructionsObject.context == "") return emitToastr("error", "Escreva o contexto, a base de conhecimento do chatbot.");

            this.loading = true;
            const resp = await Api.createOrUpdateChatbot(this.object);
            this.loading = false;

            if (resp.error) {
                emitToastr("error", resp.message || "Erro ao carregar chatbots")
            }
            else {
                if (resp.message) {
                    emitToastr("success", "Chatbot enviado com sucesso.")
                    this.getObjects();
                    this.dialogCreate = false;
                    this.clear();
                }
            }

        },

        async deleteChatbot() {
            this.object.filed = true;
            this.loading = true;
            const resp = await Api.createOrUpdateChatbot(this.object);
            this.loading = false;

            if (!resp.error && resp.message) {
                emitToastr("success", "Chatbot apagado com sucesso.")
                this.getObjects();
                this.dialogDelete = false;
                this.clear();
            }
        },

        newChatbotByModel(model) {
            this.selectedModel = model;
            this.dialogNewModel = true;
            this.newChatbotModel = {};
            this.newChatbotText = '';
        },

        createChatbotText() {
            for (const i in this.selectedModel.content) {
                if (this.selectedModel.content[i].required && !this.newChatbotModel[this.selectedModel.content[i].name]) return emitToastr("error", `É necessário preencher o campo: ${this.selectedModel.content[i].description}`)
            }

            
            let text = "";

            for (const i in this.selectedModel.content) {
                if (this.newChatbotModel[this.selectedModel.content[i].name] || this.selectedModel.content[i].type == 'text') {
                    let obj = {};
                    obj[this.selectedModel.content[i].name] = this.newChatbotModel[this.selectedModel.content[i].name];
                    text += `${this.$renderChatbotText(this.selectedModel.content[i].text, obj)}\n\n`;
                }
            }

            if (text) {
                this.object.instructionsObject.context = text;
                this.object.instructionsObject.fallback = "Não tenho essa informação no momento";
                if (this.selectedModel.personality_types) this.object.instructionsObject.personality_types = this.selectedModel.personality_types;
                if (this.selectedModel.persona_mission) this.object.instructionsObject.persona_mission = this.selectedModel.persona_mission;
                if (this.selectedModel.chatbot_auto_transfer_message) this.object.chatbot_auto_transfer_message = this.selectedModel.chatbot_auto_transfer_message;
                this.object.response_delay = 2;
                this.object.redirect = 'human';
                this.object.chatbot_auto_transfer = true;
                this.object.chatbot_auto_transfer_if_is_media = true;
                this.dialogNewModel = false;
                this.dialogCreate = true;
            }
        }
    },
};
</script>

<style scoped>
.small-text {
    font-size: 12px;
}
</style>