<template>
    <div class="menu-page">
        <div>
            <div>
                <v-row class="mb-1">
                    <v-col cols="12">
                        <v-select class="chip-select mr-2 mb-2" color="primary" dense v-model="selected_chip" :items="chips" item-text="name" item-value="_id" outlined hide-details="auto" @change="updateReports(interval)"></v-select>
                        <v-btn color="primary" height="40px" class="mr-2 mb-2 mt-1" :outlined="interval != 1" @click.native="getReports(1)" :loading="loading">Hoje</v-btn>
                        <v-btn color="primary" height="40px" class="mr-2 mb-2 mt-1" :outlined="interval != 7" @click.native="getReports(7)" :loading="loading">7 dias</v-btn>
                        <v-btn color="primary" height="40px" class="mr-2 mb-2 mt-1" :outlined="interval != 30" @click.native="getReports(30)" :loading="loading">30 dias</v-btn>
                        <v-btn color="primary" height="40px" class="mr-2 mb-2 mt-1" :outlined="interval != 90" @click.native="getReports(90)" :loading="loading">3 meses</v-btn>
                        <v-btn color="primary" height="40px" class="mr-2 mb-2 mt-1" :outlined="interval != 180" @click.native="getReports(180)" :loading="loading">6 meses</v-btn>
                        <v-btn color="primary" height="40px" class="mr-2 mb-2 mt-1" :outlined="interval != 365" @click.native="getReports(365)" :loading="loading">1 ano</v-btn>

                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" height="40px" class="mr-2 mb-2 mt-1" :outlined="(typeof (interval) != 'object')" :loading="loading" v-bind="attrs" v-on="on">Intervalo personalizado</v-btn>
                            </template>
                            <v-date-picker locale="pt-BR" range v-model="date" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">
                                    Cancelar
                                </v-btn>
                                <v-btn text color="primary" @click="saveDate(date)">
                                    Ok
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </div>
            <div>
                <h2 class="mb-2">Tempo real</h2>
                <v-row>
                    <v-col cols="12" md="3" lg="3" sm="12" xs="12">
                        <v-card class="mx-auto pt-4 pb-4 ">
                            <v-card-title class="py-2">
                                <div class="card-row">
                                    <v-icon class="icon" color="primary" left> mdi-message-outline </v-icon>
                                    <span class="text-h3">
                                        <animated-number :value="realTimeData.open_conversations?.all || 0" round="1" :duration="600" />
                                    </span>
                                </div>

                                <div right class="div-text subtitle-1 right mt-1">
                                    Conversas em andamento
                                </div>
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" sm="12" xs="12">
                        <v-card class="mx-auto pt-4 pb-4 ">
                            <v-card-title class="py-2">
                                <div class="card-row">
                                    <v-icon class="icon" color="error lighten-1" left> mdi-message-text-clock-outline </v-icon>
                                    <span class="text-h3">
                                        <animated-number :value="realTimeData.open_conversations?.waiting_response || 0" round="1" :duration="600" />
                                    </span>
                                </div>

                                <div right class="div-text subtitle-1 right mt-1">
                                    Aguardando resposta
                                </div>
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" sm="12" xs="12">
                        <v-card class="mx-auto pt-4 pb-4 ">
                            <v-card-title class="py-2">
                                <div class="card-row">
                                    <v-icon class="icon" color="primary" left> mdi-robot-outline</v-icon>
                                    <span class="text-h3">
                                        <animated-number :value="realTimeData.open_conversations?.chatbot || 0" round="1" :duration="600" />
                                    </span>
                                </div>

                                <div right class="div-text subtitle-1 right mt-1">
                                    Atendimento chatbot
                                </div>
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" sm="12" xs="12">
                        <v-card class="mx-auto pt-4 pb-4 ">
                            <v-card-title class="py-2">
                                <div class="card-row">
                                    <v-icon class="icon" color="warning" left> mdi-monitor-account </v-icon>
                                    <span class="text-h3">
                                        <animated-number :value="realTimeData.open_conversations?.manual || 0" round="1" :duration="600" />
                                    </span>
                                </div>

                                <div right class="div-text subtitle-1 right mt-1">
                                    Atendimento humano
                                </div>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <div>
                <h2 class="mt-6 mb-3">Relatórios</h2>
            </div>

            <v-row>
                <v-col cols="12" lg="9" md="8" sm="12">
                    <v-row>
                        <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                            <v-card class="mx-auto pt-4 pb-4 ">
                                <v-card-title class="py-2">
                                    <div class="card-row">
                                        <v-icon class="icon" color="primary" left> mdi-message-outline </v-icon>
                                        <span class="text-h3">
                                            <animated-number :value="conversation_cards?.all || 0" round="1" :duration="600" />
                                        </span>
                                    </div>

                                    <div right class="div-text subtitle-1 right mt-1">
                                        Novas conversas
                                    </div>
                                </v-card-title>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                            <v-card class="mx-auto pt-4 pb-4 ">
                                <v-card-title class="py-2">
                                    <div class="card-row">
                                        <v-icon class="icon" color="primary" left> mdi-robot-outline </v-icon>
                                        <span class="text-h3">
                                            <animated-number :value="conversation_cards?.chatbot || 0" round="1" :duration="600" />
                                        </span>
                                    </div>

                                    <div right class="div-text subtitle-1 right mt-1">
                                        Atendidas por chatbot
                                    </div>
                                </v-card-title>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="4" lg="4" sm="12" xs="12">
                            <v-card class="mx-auto pt-4 pb-4 ">
                                <v-card-title class="py-2">
                                    <div class="card-row">
                                        <v-icon class="icon" color="warning" left> mdi-monitor-account </v-icon>
                                        <span class="text-h3">
                                            <animated-number :value="conversation_cards?.manual || 0" round="1" :duration="600" />
                                        </span>
                                    </div>

                                    <div right class="div-text subtitle-1 right mt-1">
                                        Atendidas por humano
                                    </div>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row class="mt-4">
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>
                                    Evolução das conversas
                                </v-card-title>
                                <v-card-text>
                                    <div id="chart1">
                                        <apexChart ref="chart1" height="350" type="area" :options="chart1Options" :series="series1"></apexChart>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row class="mt-4">
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>
                                    Conversas por departamento
                                </v-card-title>
                                <v-card-text>
                                    <div id="chart2">
                                        <apexChart ref="chart2" height="350" type="area" :options="chart1Options" :series="series2"></apexChart>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" lg="3" md="4" sm="12">
                    <v-card class="fill-height">
                        <v-card-title>Últimos contatos</v-card-title>
                        <v-card-text>
                            <v-list>
                                <v-list-item-group color="primary">
                                    <v-list-item v-for="(item, i) in realTimeData.last_contacts" :key="i">
                                        <v-list-item-icon class="my-2">
                                            <v-avatar size="45">
                                                <v-img :src="item.profile_picture" v-if="item.profile_picture" />
                                                <div v-else class="w-full h-full primary d-flex justify-center">
                                                    <v-icon color="white" style="font-size:45px">mdi-account</v-icon>
                                                </div>
                                            </v-avatar>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="12">
                    <v-card class="fill-height">
                        <v-card-title>Distribuição por departamento</v-card-title>
                        <v-card-text>
                            <div id="chart3">
                                <apexChart ref="chart3" type="pie" height="400" :options="chart3Options" :series="series3"></apexChart>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="6" md="6" sm="12" v-if="selected_chip == -1">
                    <v-card class="fill-height">
                        <v-card-title>Distribuição por tags</v-card-title>
                        <v-card-text>
                            <div id="chart4">
                                <apexChart ref="chart4" type="pie" height="400" :options="chart4Options" :series="series4"></apexChart>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>

        </div>
    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import AnimatedNumber from "animated-number-vue";

export default {
    name: "DashboardView",

    data() {
        return {
            loading: false,
            interval: 7,

            menu: false,
            date: null,
            chips: [],
            selected_chip: -1,

            conversation_cards: { all: 0, chatbot: 0, manual: 0 },

            series1: [],
            series2: [],
            series3: [],
            series4: [],

            departments_labels: [],

            chart1Options: {
                grid: { show: false },
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: true
                },
                legend: {
                    position: 'top',
                    fontSize: '14px',
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    categories: [""],
                    convertedCatToNumeric: false,
                    labels: {
                        formatter: function (val) {
                            return new Date(`${val} 12:00:00`).toLocaleDateString('pt-BR');
                        },
                        style: {
                            colors: "#000",
                        },
                    },
                },
                yaxis: {
                    title: {
                        text: "Conversas",
                        style: {
                            color: "#000",
                        },
                    },
                    labels: {
                        style: {
                            colors: "#FFFFFF",
                        },
                    },
                },
            },

            chart3Options: {
                labels: [],
                grid: { show: false },
                dataLabels: {
                    enabled: true
                },
                legend: {
                    position: 'top',
                    fontSize: '14px',
                },
            },

            chart4Options: {
                labels: [],
                grid: { show: false },
                dataLabels: {
                    enabled: true
                },
                legend: {
                    position: 'top',
                    fontSize: '14px',
                },
            },
        };
    },

    components: { AnimatedNumber },

    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        },

        realTimeData() {
            return this.$store.state.realtime.data;
        },

        getIntervalName() {
            switch (this.interval) {
                case 1:
                    return 'Hoje'
                case 7:
                    return '7 dias'
                case 30:
                    return '30 dias'
                case 90:
                    return '3 meses'
                case 180:
                    return '6 meses'
                case 365:
                    return '1 ano'

                default:
                    return 'Intervalo personalizado'
            }
        },
    },

    async mounted() {
        this.$store.dispatch('realtime/load', this.selected_chip);

        this.getReports(this.interval);
        let chips = [{ name: "Todos os canais", _id: -1 }];

        const resp = await Api.getChipNumbersByUser();

        if (resp.message) {
            resp.message.forEach(c => {
                chips.push({ name: c.name, _id: c._id.toString() })
            })
        }
        this.chips = chips;

        //if (this.currentUser?._id && !window.localStorage.getItem('tips1')) this.$root.$refs.showTips();
    },

    methods: {
        saveDate(date) {
            this.menu = false;
            this.getReports(date);
        },

        updateReports(interval) {
            this.getReports(interval);
            this.$store.dispatch('realtime/load', this.selected_chip);
        },

        async getReports(interval) {
            this.loading = true;
            this.interval = interval;
            const resp = await Api.getDashboardData(this.interval, this.selected_chip);
            this.loading = false;

            if (resp && resp.error) {
                return emitToastr("error", resp.message)
            }

            if (resp && resp.message) {
                if (resp.message.conversation_cards) this.conversation_cards = resp.message.conversation_cards;

                if (resp.message.conversation_chart) {
                    let cat = [];
                    let serie1 = { name: "Total de conversas", data: [] };
                    let serie2 = { name: "Atendimento chatbot", data: [] };
                    let serie3 = { name: "Atendimento humano", data: [] };

                    resp.message.conversation_chart.forEach(i => {
                        cat.push(i._id);
                        serie1.data.push(i.all);
                        serie2.data.push(i.chatbot);
                        serie3.data.push(i.manual);
                    });

                    this.series1 = [serie1, serie2, serie3];
                    this.chart1Options.xaxis.categories = cat;
                    if (this.$refs.chart1) this.$refs.chart1.refresh();
                }


                if (resp.message.conversation_chart_departments) {
                    let series2 = [];

                    resp.message.conversation_chart_departments.forEach(i => {
                        Object.keys(i).forEach(key => {
                            if (key != "_id") {
                                if (!series2.find(s => s.name == key)) series2.push({ name: key, data: [] });
                                series2.find(s => s.name == key).data.push(i[key]);
                            }
                        })
                    });

                    this.series2 = series2;
                    if (this.$refs.chart2) this.$refs.chart2.refresh();
                }

                if (resp.message.department_distribution) {
                    let series3 = [];
                    this.chart3Options.labels = [];

                    resp.message.department_distribution.forEach(i => {
                        if (i.name) {
                            this.chart3Options.labels.push(i.name);
                            series3.push(i.all);
                        }
                    });

                    this.series3 = series3;
                    if (this.$refs.chart3) this.$refs.chart3.refresh();
                }

                if (resp.message.tag_distribution) {
                    let series4 = [];
                    this.chart4Options.labels = [];

                    resp.message.tag_distribution.forEach(i => {
                        if (i.name) {
                            this.chart4Options.labels.push(i.name);
                            series4.push(i.all);
                        }
                    });

                    this.series4 = series4;
                    if (this.$refs.chart4) this.$refs.chart4.refresh();
                }
            }
        }
    },
};
</script>

<style scoped>
.mx-auto {}

.icon {
    font-size: 50px;
}

.card-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.chip-select {
    max-width: 250px;
    display: inline-flex;
}

.div-text {
    width: 100%;
    text-align: right;
}

.card-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.right {
    justify-content: flex-end !important;
}

.text-h3 {
    color: rgb(53, 53, 53);
}
</style>
