<template>
    <v-toolbar
        height="auto"
        elevation="4"
        color="darkbackground2"
        dense
    >

        <v-row no-gutters align="center" style="min-height: 4rem;">
            <v-col cols="12" md="6">
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <div style="font-size:.8rem" class="grey--text" v-if="subtitle">{{ subtitle }}</div>
            </v-col>
        </v-row>
        
    </v-toolbar>
</template>

<script>
export default {
    name: 'AppSubheader',
    computed: {
        title() {
            if (this.$store.state.pageTitle) {
                return this.$store.state.pageTitle
            }

            if (this.$route.meta.title) {
                return this.$route.meta.title
            }

            return this.$route.name
        },
        subtitle() {
            return this.$store.state.pageSubtitle
        },
        breadcrumbs(){
            const items = [
                { label: 'Painéis' }
            ]

            return items.concat( this.$store.state.breadcrumbs)
        }
    }
}
</script>