<template>
    <vue-prism-editor
        class="prism-editor"
        v-model="model"
        :highlight="highlighter"
        :line-numbers="lineNumbers"
        :style="{ height }"
    />
</template>

<script>
// import Prism Editor
import { PrismEditor as VuePrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-tomorrow.css';

export default {
    components: {
        VuePrismEditor,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        language: {
            type: String,
            default: 'javascript',
        },
        lineNumbers: {
            type: Boolean,
            default: true,
        },
        height: {
            type: String,
            default: '200px',
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        highlighter(code) {
            return highlight(code, languages[this.language]);
        },
    },
};
</script>

<style>
.prism-editor {
    background: #2d2d2d;
    color: #ccc;
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 12px 5px;
}

.prism-editor__textarea:focus {
    outline: none;
}
</style>