import { ClassicPreset } from "rete";

export default class BaseNode extends ClassicPreset.Node {
    type = "";
    category = "";
    icon = "";

    width = 340;
    data = {}

    errorMessages = [];

    usedInputs = [];
    usedOutputs = [];


    constructor(label) {
        super(label);

        this.type = this.constructor.type || this.type;
        this.category = this.constructor.category || this.category;
        this.icon = this.constructor.icon || this.icon || "mdi-cube"

        if (!this.label) {
            this.label = this.constructor.label || this.type;
        }
    }

    setLabel(label) {
        this.label = label;
        
        this.update()
    }

    setData(data) {
        Object.assign(this.data, data);

        this.update()
    }

    // reload area
    async refresh(){
        return Promise.resolve();
    }

    async load(){
        return Promise.resolve();
    }

    validate() {
        return true;
    }
}